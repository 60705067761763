import { useEffect } from "react";
import React from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";

export const ErrorPage = () => {
  useEffect(() => {
    document.title = "Not Found";
  }, []);
  const navigate = useNavigate();
  return (
    <>
      <div className="error">
        <div className="error-heading">404</div>
        <div className="error-label">Page Not Found</div>
        <div className="error-back-btn">
          <div className="solid-btn" onClick={() => navigate("/")}>
            Back to Home
          </div>
        </div>
      </div>
    </>
  );
};
