import React, { useEffect, useState } from "react";
import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import cross from "../../images/cross.svg";
import { Loader } from "../../components/Loader/Loader";
import api from "../../AxiosInstance";
import { useLocation } from "react-router-dom";
import "./style.css";
import { ErrorPage } from "../ErrorPage/ErrorPage";

export const Page = (props) => {
  const { showSideNavbar, setShowSideNavbar } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [pageData, setPageData] = useState("");
  const [error, setError] = useState(false);
  const location = useLocation();
  const { pathname } = location;

  const loadPage = () => {
    // setIsLoading(true);
    // api
    //   .get(pathname)
    //   .then((response) => {
    //     setIsLoading(false);
    //     setPageData(response.data.data);
    //     setError(false);
    //   })
    //   .catch((error) => {
    //     setIsLoading(false);
    //     setError(true);
    //   });
  };

  useEffect(() => {
    loadPage();
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  }, [pathname]);
  return (
    <>
      {isLoading && <Loader backgroundColor="white" />}
      {error ? (
        <ErrorPage />
      ) : (
        <>
          <Header setShowSideNavbar={setShowSideNavbar} />
          <div className="page">
            {showSideNavbar && (
              <div className="side-nav-bar">
                <div className="side-nav-bar-inner">
                  <div className="side-nav-bar-menu">
                    <div className="side-nav-bar-head">
                      <div
                        className="side-nav-bar-close-btn"
                        onClick={() => setShowSideNavbar(false)}
                      >
                        <img src={cross} alt=" " />
                      </div>
                    </div>
                    <div
                      className={
                        location.pathname == "/"
                          ? "side-nav-bar-item selected-side-nav"
                          : "side-nav-bar-item"
                      }
                    >
                      Home
                    </div>
                    <div className="side-nav-bar-item ">Services</div>
                    <div className="side-nav-bar-item ">Pricing</div>
                    <div className="side-nav-bar-item ">Coupons</div>
                    <div className="side-nav-bar-item ">Contact</div>
                  </div>
                </div>
              </div>
            )}
            <div className="elevate-img"></div>
            <div className="page-heading">
              <div className="page-head">{pageData?.title}</div>
            </div>
            <div
              className="page-content"
              dangerouslySetInnerHTML={{ __html: pageData?.content }}
            />
          </div>
          <Footer />
        </>
      )}
    </>
  );
};
