export const formatString = (str) => {
  if (str == "payment_methods") {
    return "Available Credits";
  }
  return str
    .replace(/_/g, " ") // Replace underscores with spaces
    .split(" ") // Split into words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(" "); // Join words back into a string
};
