import React from "react";
import facebook from "../../images/facebook.svg";
import instagram from "../../images/instagram.svg";
import linkedIn from "../../images/linkedIn.svg";
import twitter from "../../images/twitter.svg";
import copyright from "../../images/copyright.svg";
import { Link, useNavigate } from "react-router-dom";
import "./style.css";

export const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="footer">
      <div className="inner-footer">
        <div className="footer-up-container">
          <div className="footer-up">
            <div className="footer-section">
              <div className="footer-section-head">Products</div>
              <div className="footer-section-item">
                <Link to="/#cloud-computer">Cloud Computer</Link>
              </div>
              <div className="footer-section-item">
                <Link to="#cloud-storage">Cloud Storage</Link>
              </div>
              <div className="footer-section-item">
                <Link to="#security-compliance">Security & Compliance</Link>
              </div>
              <div className="footer-section-item">
                <Link to="#cloud-networking">Cloud Networking</Link>
              </div>
              <div className="footer-section-item">
                <Link to="#cloud-migration">Cloud Migration</Link>
              </div>
            </div>
            <div className="footer-section">
              <div className="footer-section-head">Features</div>
              <div className="footer-section-item">
                <Link to="#data-center-locations">Data Center Locations</Link>
              </div>
              <div className="footer-section-item">
                <Link to="#operating-systems">Operating Systems</Link>
              </div>
              <div className="footer-section-item">
                <Link to="#advanced-network">Advanced Network</Link>
              </div>
              <div className="footer-section-item">
                <Link to="#helpdesk">Helpdesk</Link>
              </div>
              <div className="footer-section-item">
                <Link to="#disaster-recovery">Disaster Recovery</Link>
              </div>
            </div>
            <div className="footer-section-company">
              <div className="footer-section-head">Company</div>
              <div className="footer-section-item">
                <Link to="#our-team">Our Team</Link>
              </div>
              <div className="footer-section-item">
                <Link to="#news">News</Link>
              </div>
              <div className="footer-section-item">
                <Link to="#reviews">Reviews</Link>
              </div>
              <div className="footer-section-item">
                <Link to="#coupons">Coupons</Link>
              </div>
              <div className="footer-section-item">
                <Link to="#contact">Contact</Link>
              </div>
            </div>
            <div className="footer-social-media">
              <a href="https://www.facebook.com/" target="_blank">
                <img src={facebook} alt="" />
              </a>
              <a href="https://www.instagram.com/" target="_blank">
                <img src={instagram} alt="" />
              </a>
              <a href="https://in.linkedin.com/" target="_blank">
                <img src={linkedIn} alt="" />
              </a>
              <a href="https://twitter.com/" target="_blank">
                <img src={twitter} alt="" />
              </a>
            </div>
          </div>
        </div>
        <div className="mob-footer-section-company">
          <div className="footer-section-head">Company</div>
          <div className="footer-section-item">
            <Link to="#our-team">Our Team</Link>
          </div>
          <div className="footer-section-item">
            <Link to="#news">News</Link>
          </div>
          <div className="footer-section-item">
            <Link to="#reviews">Reviews</Link>
          </div>
          <div className="footer-section-item">
            <Link to="#coupons">Coupons</Link>
          </div>
          <div className="footer-section-item">
            <Link to="#contact">Contact</Link>
          </div>
        </div>
        <div className="footer-down">
          <div className="footer-copyright-text">
            <img src={copyright} alt="" />
          </div>
          <div className="footer-nav-bar">
            <div className="footer-nav-item">
              <Link to="/pages/terms_conditions">
                <span>Terms & Conditions</span>{" "}
              </Link>
              <span className="mob-divider">&nbsp;|&nbsp;</span>
            </div>
            <div className="footer-nav-item">
              <Link to="/pages/privacy_policy">Privacy Policy</Link>
            </div>
          </div>
          <div className="mob-footer-social-media">
            <a href="https://www.facebook.com/" target="_blank">
              <img src={facebook} alt="" />
            </a>
            <a href="https://www.instagram.com/" target="_blank">
              <img src={instagram} alt="" />
            </a>
            <a href="https://in.linkedin.com/" target="_blank">
              <img src={linkedIn} alt="" />
            </a>
            <a href="https://twitter.com/" target="_blank">
              <img src={twitter} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
