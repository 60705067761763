import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../AxiosInstance";
import whiteEye from "../../images/white-eye.svg";
import whiteEyeCrossed from "../../images/white-eye-crossed.svg";
import blueEye from "../../images/blue-eye.svg";
import blueEyeCrossed from "../../images/blue-eye-crossed.svg";
import { Loader } from "../../components/Loader/Loader";
import { BackButton } from "../../components/BackButton/BackButton";
import "./style.css";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState("");

  const accessToken = localStorage.getItem("accessToken");

  const navigate = useNavigate();

  const validations = () => {
    const errors = {};

    if (!email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email address is invalid";
    }

    if (!password) {
      errors.password = "Password is required";
    }

    return errors;
  };

  const login = () => {
    const validationError = validations();
    if (Object.keys(validationError).length === 0) {
      setIsLoading(true);
      const payload = {
        email: email,
        password: password,
      };
      api
        .post("/signin", payload)
        .then((response) => {
          setIsLoading(false);
          if (response.data.success) {
            localStorage.setItem("accessToken", response.data.data.token);
            setErrors({});
            if (response.data.data.user.email_verified_at == null) {
              sendEmailVerificationCode();
            } else if (response.data.data.user.mobile_verified_at == null) {
              sendMobileVerificationCode();
            } else {
              navigate("/dashboard");
            }
          } else {
            setError(response.data.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    } else {
      setErrors(validationError);
    }
  };

  const sendEmailVerificationCode = () => {
    api
      .post(
        "/email-verification-code",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        if (response.data.success) {
          navigate("/verify-email");
        } else {
          setError(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const sendMobileVerificationCode = () => {
    api
      .post(
        "/mobile-verification-code",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        if (response.data.success) {
          navigate("/verify-mobile");
        } else {
          setError(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleEmailChange = (e) => {
    setErrors({ ...errors, email: null });
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setErrors({ ...errors, password: null });
    setPassword(e.target.value);
  };

  useEffect(() => {
    if (accessToken) {
      navigate("/dashboard");
    }
    document.title = "Login";
  }, []);

  return (
    <div className="login">
      {isLoading && <Loader />}
      <div className="login-inner">
        <div className="login-head">
          <BackButton handleClick={() => navigate("/")} />
        </div>
        <div className="login-body">
          <div className="login-card">
            <div className="card-head">Welcome Back</div>
            <div className="card-label">Login with Email</div>
            <div className="input-box">
              <div className="input-label">Email Id</div>
              <div className="input-label-mob email">Email Id</div>
              <div className="input-field">
                <input type="email" onChange={(e) => handleEmailChange(e)} />
                {errors.email != null && (
                  <div className="error-text">{errors.email}</div>
                )}
              </div>
            </div>
            <div className="input-box">
              <div className="input-label">Password</div>
              <div className="input-label-mob password">Password</div>
              <div className="input-field">
                <input
                  type={showPassword ? "password" : "text"}
                  onChange={(e) => handlePasswordChange(e)}
                />
                <div
                  className="password-eye"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <img src={whiteEye} alt="" className="mobile-icon" />
                  ) : (
                    <img src={whiteEyeCrossed} alt="" className="mobile-icon" />
                  )}
                  {showPassword ? (
                    <img src={blueEye} alt="" className="desktop-icon" />
                  ) : (
                    <img src={blueEyeCrossed} alt="" className="desktop-icon" />
                  )}
                </div>
                {errors.password != null && (
                  <div className="error-text">{errors.password}</div>
                )}
              </div>
            </div>
            {error != "" && <div className="error-text">{error}</div>}
            <div
              className="forgot-pass"
              onClick={() => navigate("/forgot-password")}
            >
              Forgot your password?
            </div>
            <div className="login-submit-btn" onClick={() => login()}>
              LOGIN
            </div>
            <div className="or-section">
              <div className="or-text">OR</div>
            </div>
            <div className="register-text">
              Don’t have account?{" "}
              <strong
                onClick={() => navigate("/signup")}
                style={{ cursor: "pointer" }}
              >
                Register Now
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
