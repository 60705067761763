import React, { useEffect, useState } from "react";
import service1 from "../../images/service1.svg";
import service2 from "../../images/service2.svg";
import cross from "../../images/cross.svg";
import plus from "../../images/plus.svg";
import minus from "../../images/minus.svg";
import explore from "../../images/explore-mobile.svg";
import "./style.css";
import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";

export const Homepage = (props) => {
  const { showSideNavbar, setShowSideNavbar } = props;
  const [keyDiff, setKeyDiff] = useState(1);
  const [expandKeyDiff, setExpandKeyDiff] = useState(true);

  const handleKeyDiffClick = (key, value) => {
    setKeyDiff(key);
    setExpandKeyDiff(value);
  };

  useEffect(() => {
    document.title = "Home";
  },[])
  return (
    <>
      <Header setShowSideNavbar={setShowSideNavbar} />
      <div className="homepage">
        {showSideNavbar && (
          <div className="side-nav-bar">
            <div className="side-nav-bar-inner">
              <div className="side-nav-bar-menu">
                <div className="side-nav-bar-head">
                  <div
                    className="side-nav-bar-close-btn"
                    onClick={() => setShowSideNavbar(false)}
                  >
                    <img src={cross} alt=" " />
                  </div>
                </div>
                <div className="side-nav-bar-item selected-side-nav">Home</div>
                <div className="side-nav-bar-item ">Services</div>
                <div className="side-nav-bar-item ">Pricing</div>
                <div className="side-nav-bar-item ">Coupons</div>
                <div className="side-nav-bar-item ">Contact</div>
              </div>
            </div>
          </div>
        )}
        <div className="elevate-img"></div>
        <div className="elevate-container">
          <div className="elevate-inner">
            <div className="elevate-left">
              <div className="elevate-title-text">
                Elevate Your Business with Cutting-Edge <br /> Cloud Technology
              </div>
              <div className="elevate-desc-text">
                Empower your business with our comprehensive cloud solutions
                designed for scalability, security, and flexibility. Experience
                seamless integration, real-time data access, and powerful
                computing resources tailored to your business needs—all backed
                by industry-leading support and reliability.
              </div>
            </div>
            <div className="elevate-right"></div>
          </div>
        </div>
        <div className="services-container">
          <div className="service-item">
            <img src={service1} alt="" />
          </div>
          <div className="service-item">
            <img src={service2} alt="" />
          </div>
          <div className="service-item">
            <img src={service1} alt="" />
          </div>
          <div className="service-item">
            <img src={service2} alt="" />
          </div>
          <div className="service-item">
            <img src={service1} alt="" />
          </div>
        </div>
        <div className="mobile-services-container">
          <div className="service-up">
            <div className="service-item">
              <img src={service1} alt="" />
            </div>
            <div className="service-item">
              <img src={service2} alt="" />
            </div>
          </div>
          <div className="service-down">
            <div className="service-item">
              <img src={service1} alt="" />
            </div>
            <div className="service-item">
              <img src={service2} alt="" />
            </div>
            <div className="service-item">
              <img src={service1} alt="" />
            </div>
          </div>
        </div>
        <div className="explore-container">
          <div className="explore-head">
            <div className="explore-heading">Explore Our Cloud Solutions</div>
            <div className="mob-explore-img">
              <img src={explore} alt="" />
            </div>
            <div className="view-all-text">{"View all >"}</div>
          </div>
          <div className="explore-body">
            <div className="explore-left">
              <div className="explore-card">
                <div className="explore-card-title">Cloud Storage Services</div>
                <div className="explore-card-desc">
                  Store unlimited amounts of data securely and retrieve it from
                  anywhere.
                </div>
              </div>
              <div className="explore-card">
                <div className="explore-card-title">
                  Cloud Security Services
                </div>
                <div className="explore-card-desc">
                  Secure your infrastructure and data with cutting-edge
                  firewalls, identity management, and compliance tools.
                </div>
              </div>
            </div>
            <div className="explore-left">
              <div className="explore-card">
                <div className="explore-card-title">
                  Cloud Infrastructure Services
                </div>
                <div className="explore-card-desc">
                  Access powerful infrastructure with virtual machines,
                  containers, and networks. Scale on demand with high
                  availability.
                </div>
              </div>
              <div className="explore-card">
                <div className="explore-card-title">
                  Cloud Backup & Recovery
                </div>
                <div className="explore-card-desc">
                  Safeguard your data with automated backups and disaster
                  recovery solutions.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="key-differentiators">
          <div className="inner-differentiators">
            <div className="differentiators-left">
              <div className="differentiators-heading">Key Differentiators</div>
              {keyDiff == 1 && expandKeyDiff ? (
                <div
                  className="industry-specialization"
                  onClick={() => handleKeyDiffClick(1, false)}
                >
                  <div className="industry-title">
                    - Industry Specialization
                  </div>
                  <div className="industry-desc">
                    Tailoring services to specific industries (e.g., healthcare,
                    finance, legal) allows MSPs to offer deeper expertise,
                    customized solutions, and compliance management.
                  </div>
                </div>
              ) : (
                <div
                  className="differentiators-item"
                  onClick={() => handleKeyDiffClick(1, true)}
                >
                  + Industry Specialization
                </div>
              )}

              {keyDiff == 2 && expandKeyDiff ? (
                <div
                  className="industry-specialization"
                  onClick={() => handleKeyDiffClick(2, false)}
                >
                  <div className="industry-title">
                    - Advanced Security Offerings
                  </div>
                  <div className="industry-desc">
                    Tailoring services to specific industries (e.g., healthcare,
                    finance, legal) allows MSPs to offer deeper expertise,
                    customized solutions, and compliance management.
                  </div>
                </div>
              ) : (
                <div
                  className="differentiators-item"
                  onClick={() => handleKeyDiffClick(2, true)}
                >
                  + Advanced Security Offerings
                </div>
              )}
              {keyDiff == 3 && expandKeyDiff ? (
                <div
                  className="industry-specialization"
                  onClick={() => handleKeyDiffClick(3, false)}
                >
                  <div className="industry-title">
                    - Superior Customer Service
                  </div>
                  <div className="industry-desc">
                    Tailoring services to specific industries (e.g., healthcare,
                    finance, legal) allows MSPs to offer deeper expertise,
                    customized solutions, and compliance management.
                  </div>
                </div>
              ) : (
                <div
                  className="differentiators-item"
                  onClick={() => handleKeyDiffClick(3, true)}
                >
                  + Superior Customer Service
                </div>
              )}
              {keyDiff == 4 && expandKeyDiff ? (
                <div
                  className="industry-specialization"
                  onClick={() => handleKeyDiffClick(4, false)}
                >
                  <div className="industry-title">- Proven Track Record</div>
                  <div className="industry-desc">
                    Tailoring services to specific industries (e.g., healthcare,
                    finance, legal) allows MSPs to offer deeper expertise,
                    customized solutions, and compliance management.
                  </div>
                </div>
              ) : (
                <div
                  className="differentiators-item"
                  onClick={() => handleKeyDiffClick(4, true)}
                >
                  + Proven Track Record
                </div>
              )}
              {keyDiff == 5 && expandKeyDiff ? (
                <div
                  className="industry-specialization"
                  onClick={() => handleKeyDiffClick(5, false)}
                >
                  <div className="industry-title">
                    - Focus on business outcomes
                  </div>
                  <div className="industry-desc">
                    Tailoring services to specific industries (e.g., healthcare,
                    finance, legal) allows MSPs to offer deeper expertise,
                    customized solutions, and compliance management.
                  </div>
                </div>
              ) : (
                <div
                  className="differentiators-item"
                  onClick={() => handleKeyDiffClick(5, true)}
                >
                  + Focus on business outcomes
                </div>
              )}
              {keyDiff == 6 && expandKeyDiff ? (
                <div
                  className="industry-specialization"
                  onClick={() => handleKeyDiffClick(6, false)}
                >
                  <div className="industry-title">
                    - Local presence with global reach
                  </div>
                  <div className="industry-desc">
                    Tailoring services to specific industries (e.g., healthcare,
                    finance, legal) allows MSPs to offer deeper expertise,
                    customized solutions, and compliance management.
                  </div>
                </div>
              ) : (
                <div
                  className="differentiators-item"
                  onClick={() => handleKeyDiffClick(6, true)}
                >
                  + Local presence with global reach
                </div>
              )}
            </div>
            <div className="differentiators-right"></div>
          </div>
          <div className="mob-diff-heading">Key Differentiators</div>
        </div>
        <div className="software-installation">
          <div className="software-up">
            One-Click Software Installation For Your Virtual Server
          </div>
          <div className="software-down">
            <div className="software-ins-left"></div>
            <div className="software-ins-right">
              <div className="software-ins-card">
                <div className="software-card-head">
                  Wide Range of Operating Systems
                </div>
                <div className="software-card-body">
                  Discover a variety of Linux-based operating systems for your
                  virtual servers and we’ll handle the automatic installation
                  for you.
                </div>
              </div>
              <div className="software-ins-card">
                <div className="software-card-head">Control Panel and Apps</div>
                <div className="software-card-body">
                  Manage your servers effortlessly with a dependable control
                  panel and streamline web app installations wit ease. Our VPS
                  templates help set your applications in mere minutes.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="testimonials">
          <div className="testimonials-head">Customer Testimonials</div>
          <div className="testimonials-body">
            <div className="testimonial-item1"></div>
            <div className="testimonial-item2"></div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
