
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../AxiosInstance";
import { Loader } from "../../components/Loader/Loader";
import { BackButton } from "../../components/BackButton/BackButton";
import "./style.css";

export const Register = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckedTerms, setIsCheckedTerms] = useState(false);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const validations = () => {
    const errors = {};

    if (!firstName) {
      errors.firstName = "First name is required";
    }

    if (!lastName) {
      errors.lastName = "Last name is required";
    }

    if (!phone) {
      errors.phone = "Phone number is required";
    }

    if (!email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email address is invalid";
    }

    if (!password) {
      errors.password = "Password is required";
    }

    if (!confirmPassword) {
      errors.confirmPassword = "Confirm password is required";
    } else if (confirmPassword != password) {
      errors.confirmPassword = "Passwords must be same";
    }

    if (!isCheckedTerms) {
      errors.isCheckedTerms =
        "You must accept the terms and conditions to register.";
    }

    return errors;
  };

  useEffect(() => {
    document.title = "Register";
  }, []);

  const register = () => {
    const validationError = validations();
    if (Object.keys(validationError).length === 0) {
      setIsLoading(true);
      const payload = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        mobile: phone,
        password: password,
        confirm_password: confirmPassword,
      };
      api
        .post("/signup", payload)
        .then((response) => {
          if (response.data.success) {
            localStorage.setItem("accessToken", response.data.data.token);
            setErrors({});
            setError("");
            sendEmailVerificationCode();
          } else {
            setError(response.data.message);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    } else {
      setErrors(validationError);
    }
  };

  const sendEmailVerificationCode = () => {
    api
      .post(
        "/email-verification-code",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        navigate("/verify-email");
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleFirstNameChange = (e) => {
    setErrors({ ...errors, firstName: null });
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setErrors({ ...errors, lastName: null });
    setLastName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setErrors({ ...errors, email: null });
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setErrors({ ...errors, phone: null });
    setPhone(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setErrors({ ...errors, password: null });
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setErrors({ ...errors, confirmPassword: null });
    setConfirmPassword(e.target.value);
  };

  const handleTermsCheckboxChange = (e) => {
    setIsCheckedTerms(e.target.checked);
  };
  return (
    <div className="register">
      {isLoading && <Loader />}
      <div className="register-inner">
        <div className="register-head">
          <BackButton handleClick={() => navigate("/")} />
        </div>
        <div className="register-body">
          <div className="register-card">
            <div className="card-head">Register</div>
            <div className="input-box">
              <div className="input-label">First Name</div>
              <div className="input-label-mob first">First Name</div>
              <div className="input-field">
                <input type="text" onChange={(e) => handleFirstNameChange(e)} />
                {errors.firstName != null && (
                  <div className="error-text">{errors.firstName}</div>
                )}
              </div>

              <div className="input-field">
                <div className="input-label1">Last Name</div>
                <div className="input-label-mob1 last">Last Name</div>
                <input type="text" onChange={(e) => handleLastNameChange(e)} />
                {errors.lastName != null && (
                  <div className="error-text">{errors.lastName}</div>
                )}
              </div>
            </div>
            <div className="input-box">
              <div className="input-label">Email Id</div>
              <div className="input-label-mob email">Email Id</div>
              <div className="input-field">
                <input type="email" onChange={(e) => handleEmailChange(e)} />
                {errors.email != null && (
                  <div className="error-text">{errors.email}</div>
                )}
              </div>

              <div className="input-field">
                <div className="input-label1">Phone Number</div>
                <div className="input-label-mob1 phone">Phone Number</div>
                <input type="number" onChange={(e) => handlePhoneChange(e)} />
                {errors.phone != null && (
                  <div className="error-text">{errors.phone}</div>
                )}
              </div>
            </div>
            <div className="input-box">
              <div className="input-label">Password</div>
              <div className="input-label-mob password">Password</div>
              <div className="input-field">
                <input
                  type="password"
                  onChange={(e) => handlePasswordChange(e)}
                />
                {errors.password != null && (
                  <div className="error-text">{errors.password}</div>
                )}
              </div>

              <div className="input-label1">Confirm Password</div>
              <div className="input-label-mob1 confirm">Confirm Password</div>
              <div className="input-field">
                <input
                  type="password"
                  onChange={(e) => handleConfirmPasswordChange(e)}
                />
                {errors.confirmPassword != null && (
                  <div className="error-text">{errors.confirmPassword}</div>
                )}
              </div>
            </div>
            {error != "" && <div className="error-text">{error}</div>}
            <div className="agree-section">
              <div className="agree-checkbox">
                <input
                  type="checkbox"
                  checked={isCheckedTerms}
                  onChange={(e) => handleTermsCheckboxChange(e)}
                />
              </div>

              <div className="agree-text">
                I have read and accept all the{" "}
                <a
                  href="/pages/terms_conditions"
                  target="_blank"
                  className="text-link"
                >
                  Terms And Conditions{" "}
                </a>{" "}
                and{" "}
                <a
                  href="/pages/privacy_policy"
                  target="_blank"
                  className="text-link"
                >
                  Privacy Policy.
                </a>
              </div>
            </div>
            {errors.isCheckedTerms != null && (
              <div className="error-text">{errors.isCheckedTerms}</div>
            )}
            <div className="register-submit-btn" onClick={() => register()}>
              Submit
            </div>

            <div className="or-section">
              <div className="or-text">OR</div>
            </div>
            <div className="register-text">
              Already have an account?{" "}
              <strong
                onClick={() => navigate("/login")}
                style={{ cursor: "pointer" }}
              >
                Login
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
