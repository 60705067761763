import React from "react";
import cross from "../../images/cross.svg";
import "./style.css";
import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";

export const TermsAndConditionns = (props) => {
  const { showSideNavbar, setShowSideNavbar } = props;
  return (
    <>
      <Header setShowSideNavbar={setShowSideNavbar} />
      <div className="terms">
        {showSideNavbar && (
          <div className="side-nav-bar">
            <div className="side-nav-bar-inner">
              <div className="side-nav-bar-menu">
                <div className="side-nav-bar-head">
                  <div
                    className="side-nav-bar-close-btn"
                    onClick={() => setShowSideNavbar(false)}
                  >
                    <img src={cross} alt=" " />
                  </div>
                </div>
                <div className="side-nav-bar-item selected-side-nav">Home</div>
                <div className="side-nav-bar-item ">Services</div>
                <div className="side-nav-bar-item ">Pricing</div>
                <div className="side-nav-bar-item ">Coupons</div>
                <div className="side-nav-bar-item ">Contact</div>
              </div>
            </div>
          </div>
        )}
        <div className="elevate-img"></div>
        <div className="terms-heading">
          <div className="terms-title">Terms and Conditions</div>
        </div>
        <div className="terms-content">
          Welcome to Capricorncorp.com. These terms and conditions outline the
          rules and regulations for the use of Capricorncorp.com's website,
          located at [www.capricorncorp.com]. By accessing this website, you
          accept these terms and conditions in full. Do not continue to use
          Capricorncorp.com's website if you do not agree to all of the terms
          and conditions stated on this page. 1. Introduction The following
          terminology applies to these Terms and Conditions, Privacy Statement,
          and Disclaimer Notice, and all Agreements: "Client", "You", and "Your"
          refers to you, the person accessing this website and accepting the
          Company's terms and conditions. "The Company", "Ourselves", "We",
          "Our", and "Us" refers to Capricorncorp.com. "Party", "Parties", or
          "Us" refers to both the Client and ourselves. All terms refer to the
          offer, acceptance, and consideration of payment necessary to undertake
          the process of our assistance to the Client in the most appropriate
          manner, whether through consulting, IT services, or software
          development, in accordance with and subject to prevailing law. 2.
          Services Capricorncorp.com provides a range of IT services including,
          but not limited to, cloud solutions, software development, system
          integration, cybersecurity, and managed IT services. The use of our
          services is subject to the terms laid out here, and by using our
          services, you agree to comply with all applicable regulations. 3.
          Intellectual Property Rights Unless otherwise stated,
          Capricorncorp.com and/or its licensors own the intellectual property
          rights for all material on this website, including but not limited to
          text, code, images, and software. All intellectual property rights are
          reserved. You may view and/or print pages from www.capricorncorp.com
          for your own personal use, subject to restrictions set in these terms
          and conditions. You must not: ·        • Republish material from
          Capricorncorp.com ·        • Sell, rent, or sub-license material from
          Capricorncorp.com ·        • Reproduce, duplicate, or copy material
          from Capricorncorp.com for commercial use ·        • Redistribute
          content from Capricorncorp.com unless content is specifically made for
          redistribution (e.g., blog posts or resources shared under Creative
          Commons) 4. Use of Services You agree to use our services only for
          lawful purposes. You are prohibited from using our website or services
          to: ·        • Engage in any fraudulent, unlawful, or harmful activity
          ·        • Violate intellectual property rights of Capricorncorp.com
          or any third party ·        • Transmit or distribute any viruses,
          malware, or other harmful software ·        • Attempt to breach the
          security of Capricorncorp.com's systems or networks Violation of these
          conditions may result in termination of services without notice and
          possible legal action. 5. Limitation of Liability While we strive to
          deliver high-quality IT services, Capricorncorp.com shall not be held
          liable for: ·        • Any direct, indirect, or consequential loss or
          damage arising under these terms and conditions or in connection with
          our services ·        • Any errors or interruptions in the service,
          including but not limited to server failure or other technical issues
          ·        • Loss of data, profits, or business opportunity as a result
          of using our services 6. User Comments and Content Parts of this
          website may allow users to post comments or share content. By
          submitting comments or content, you grant Capricorncorp.com a
          non-exclusive license to use, reproduce, and distribute the content
          across any medium. You must not post any material that is: ·        •
          Defamatory, offensive, or otherwise inappropriate ·        •
          Infringing on third-party rights ·        • Illegal or promoting
          illegal activity Capricorncorp.com reserves the right to remove any
          content that breaches these terms. 7. Privacy and Data Protection
          Capricorncorp.com respects your privacy and is committed to protecting
          any personal data you share with us. Please refer to our Privacy
          Policy for detailed information on how we collect, use, and protect
          your data. 8. Links to Third-Party Sites Our website may include links
          to external websites. Capricorncorp.com has no control over the
          content of these websites and cannot be held responsible for any
          damages or losses that may arise from using them. Any link to a
          third-party website is not an endorsement of that site. 9.
          Modifications to Terms Capricorncorp.com reserves the right to revise
          these terms at any time. By continuing to use our website and services
          after changes are made, you accept the revised terms and conditions.
          It is your responsibility to regularly check this page for updates.
          10. Governing Law These terms and conditions are governed by and
          construed in accordance with the laws of [Your Jurisdiction], and any
          disputes relating to these terms will be subject to the exclusive
          jurisdiction of the courts in that jurisdiction.   By using our
          website or services, you agree to these terms. If you have any
          questions or concerns, please contact us at [insert contact email or
          phone number].
        </div>
      </div>
      <Footer />
    </>
  );
};
