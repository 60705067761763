import React, { useEffect, useState } from "react";
import logo from "../../images/logo.svg";
import darkLogo from "../../images/logo-dark.svg";
import hamburger from "../../images/hamburger.svg";
import darkHamburger from "../../images/hamburger-dark.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./style.css";

export const Header = (props) => {
  const [headerClass, setHeaderClass] = useState("");
  const { setShowSideNavbar } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const accessToken = localStorage.getItem("accessToken");

  const toggleVisibility = () => {
    if (window.pageYOffset > 200) {
      setHeaderClass("scroll-header");
    } else {
      setHeaderClass("");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);
  return (
    <div className={"header " + headerClass}>
      <div className="inner-header">
        <div className="logo" onClick={() => navigate("/")}>
          <img src={headerClass == "" ? logo : darkLogo} alt="logo" />
        </div>
        <div className="header-nav">
          <div className="header-nav-bar">
            <div
              className={
                location.pathname == "/"
                  ? "nav-bar-item selected"
                  : "nav-bar-item"
              }
            >
              <Link to="/">Home</Link>
            </div>
            <div className="nav-bar-item">
              <Link to="#services">Services</Link>
            </div>
            <div className="nav-bar-item">
              <Link to="#pricing">Pricing</Link>
            </div>
            <div className="nav-bar-item">
              <Link to="#coupons">Coupons</Link>
            </div>
            <div className="nav-bar-item">
              <Link to="#contact">Contact</Link>
            </div>
          </div>
          <div className="header-btns">
            {accessToken ? (
              <Link to="/dashboard">
                <div className="solid-btn" style={{padding: "0 5px"}}>Dashboard</div>
              </Link>
            ) : (
              <>
                <Link to="/login">
                  <div className="solid-btn">Login</div>
                </Link>
                <Link to="/signup">
                  <div className="solid-btn">Sign Up</div>
                </Link>
              </>
            )}
          </div>
          <div
            className="hamburger-icon"
            onClick={() => setShowSideNavbar(true)}
          >
            <img src={headerClass == "" ? hamburger : darkHamburger} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
