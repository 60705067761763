import React from "react";
import { Oval } from "react-loader-spinner";
import "./style.css";

export const Loader = (props) => {
  const { backgroundColor } = props;
  return (
    <div
      className="loader"
      style={{ backgroundColor: backgroundColor ?? "rgba(0,0,0,.5)" }}
    >
      <Oval
        visible={true}
        height="80"
        width="80"
        color="#009EE2"
        secondaryColor="#ffe6cd"
        ariaLabel="oval-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );
};
