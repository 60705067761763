import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../AxiosInstance";
import { Loader } from "../../components/Loader/Loader";
import { BackButton } from "../../components/BackButton/BackButton";
import "./style.css";

export const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const accessToken = localStorage.getItem("accessToken");

  const validations = () => {
    const errors = {};

    if (!email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email address is invalid";
    }

    return errors;
  };

  const forgotPassword = () => {
    const validationError = validations();
    if (Object.keys(validationError).length === 0) {
      setIsLoading(true);
      const payload = {
        email: email,
      };
      api
        .post("/forgot-password", payload)
        .then((response) => {
          setIsLoading(false);
          if (response.data.success) {
            setErrors({});
            navigate("/verify-otp", { state: payload });
          } else {
            setError(response.data.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    } else {
      setErrors(validationError);
    }
  };

  const handleEmailChange = (e) => {
    setErrors({ ...errors, email: null });
    setEmail(e.target.value);
  };

  useEffect(() => {
    if (accessToken) {
      navigate("/dashboard");
    }
    document.title = "Forgot Password";
  },[])
  return (
    <div className="forgot">
      {isLoading && <Loader />}
      <div className="forgot-inner">
        <div className="forgot-head">
          <BackButton handleClick={() => navigate("/login")} />
        </div>
        <div className="forgot-body">
          <div className="forgot-card">
            <div className="card-head">Forgot Password?</div>
            <div className="card-label">
              Enter the email address associated with your account.
            </div>
            <div className="input-box">
              <div className="input-label">Email Id</div>
              <div className="input-label-mob email">Email Id</div>
              <div className="input-field">
                <input type="text" onChange={(e) => handleEmailChange(e)} />
                {errors.email != null && (
                  <div className="error-text">{errors.email}</div>
                )}
              </div>
            </div>
            {error != "" && <div className="error-text">{error}</div>}
            <div className="forgot-submit-btn" onClick={() => forgotPassword()}>
              SEND OTP
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
