import React from "react";
import { useNavigate } from "react-router-dom";
import { BackButton } from "../../components/BackButton/BackButton";
import "./style.css";

export const GetInTouch = () => {
  const navigate = useNavigate();
  return (
    <div className="get-in-touch">
      <div className="get-in-touch-inner">
        <div className="get-in-touch-head">
          <BackButton handleClick={() => navigate("/")} />
        </div>
        <div className="get-in-touch-body">
          <div className="get-in-touch-card">
            <div className="card-head">Get In Touch</div>
            <div className="input-box">
              <div className="input-label">First Name</div>
              <div className="input-label-mob first">First Name</div>
              <div className="input-field">
                <input type="text" />
              </div>
              <div className="input-field">
                <div className="input-label1">Last Name</div>
                <div className="input-label-mob1 last">Last Name</div>
                <input type="text" />
              </div>
            </div>
            <div className="input-box">
              <div className="input-label">Email Id</div>
              <div className="input-label-mob email">Email Id</div>
              <div className="input-field">
                <input type="text" />
              </div>
              <div className="input-field">
                <div className="input-label1">Phone Number</div>
                <div className="input-label-mob1 phone">Phone Number</div>
                <input type="text" />
              </div>
            </div>
            <div className="input-box">
              <div className="input-label">Service</div>
              <div className="input-label-mob service">Service</div>
              <div className="input-field">
                <input type="text" />
              </div>
              <div className="input-field">
                <div className="input-label1">Budget</div>
                <div className="input-label-mob1 budget">Budget</div>
                <input type="text" />
              </div>
            </div>
            <div className="input-box">
              <div className="input-label">Message</div>
              <div className="input-label-mob message">Message</div>
              <div className="input-field1">
                <textarea type="text" />
              </div>
            </div>
            <div className="get-in-touch-submit-btn">Submit</div>
          </div>
        </div>
      </div>
    </div>
  );
};
