import React, { useEffect, useState, useRef } from "react";
import api from "../../AxiosInstance";
import logo from "../../images/logo.svg";
import cross from "../../images/cross.svg";
import redCross from "../../images/red-cross.svg";
import greenCross from "../../images/green-cross.svg";
import darkLogo from "../../images/logo-dark.svg";
import home from "../../images/home.svg";
import database from "../../images/database.svg";
import darkDatabase from "../../images/dark-database.svg";
import hdd from "../../images/hdd.svg";
import backup from "../../images/backup.svg";
import snapshot from "../../images/snapshot.svg";
import support from "../../images/support.svg";
import search from "../../images/search.svg";
import bell from "../../images/bell.svg";
import copy from "../../images/copy.svg";
import master from "../../images/master.svg";
import window from "../../images/window.webp";
import ubuntu from "../../images/ubuntu.png";
import eye from "../../images/eye.svg";
import visa from "../../images/visa.svg";
import eyeCrossed from "../../images/eye-crossed.svg";
import plus from "../../images/dark-plus.svg";
import lightPlus from "../../images/light-plus.svg";
import hamburger from "../../images/menu-burger.svg";
import menuDots from "../../images/menu-dots.svg";
import arrowDown from "../../images/arrow-down.svg";
import { useNavigate } from "react-router-dom";
import { formatString } from "../../helpers";
import { Loader } from "../../components/Loader/Loader";
import "./style.css";

export const Dashboard = () => {
  const [showTaxIdField, setShowTaxIdField] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState("profile_details");
  const [selectedComponent, setSelectedComponent] = useState("dashboard");
  const [showActionMenu, setShowActionMenu] = useState(false);
  const [showMobileProfileMenu, setShowMobileProfileMenu] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [actionMenu, setActionMenu] = useState(0);
  const [showSidebar, setShowSidebar] = useState(false);
  const [copyText, setCopyText] = useState("Copy");
  const [copyTextKey, setCopyTextKey] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlertBox, setShowAlertBox] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPasword, setConfirmNewPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(true);
  const [showNewPassword, setShowNewPassword] = useState(true);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(true);
  const [selectedBillingType, setSelectedBillingType] = useState("");
  const [billingFullName, setBillingFullName] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [billingAddress2, setBillingAddress2] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [selectedBillingState, setSelectedBillingState] = useState("");
  const [selectedBillingCountry, setSelectedBillingCountry] = useState("");
  const [selectedBillingCountryId, setSelectedBillingCountryId] =
    useState(null);
  const [selectedBillingStateId, setSelectedBillingStateId] = useState(null);
  const [billingPincode, setBillingPincode] = useState("");
  const [billingTaxId, setBillingTaxId] = useState("");
  const [billingCountryList, setBillingCountryList] = useState([]);
  const [filteredbillingCountryList, setFilteredBillingCountryList] = useState(
    []
  );
  const [billingStateList, setBillingStateList] = useState([]);
  const [filteredbillingStateList, setFilteredBillingStateList] = useState([]);
  const [showBillingTypeList, setShowBillingTypeList] = useState(false);
  const [showBillingCountryList, setShowBillingCountryList] = useState(false);
  const [showBillingStateList, setShowBillingStateList] = useState(false);
  const [errors, setErrors] = useState({});
  const [alertText, setAlertText] = useState("");
  const navigate = useNavigate();
  const dropdownRef = useRef();
  const sidebarRef = useRef();
  const billingTypeRef = useRef();
  const billingStateRef = useRef();
  const billingCountryRef = useRef();

  const billingTypeList = [
    "individual",
    "proprietorship",
    "limited_liability_company",
    "corporation",
  ];

  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowMobileProfileMenu(false);
        setShowActionMenu(false);
        setShowProfileMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutsideSidebar = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setShowSidebar(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideSidebar);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideSidebar);
    };
  }, []);

  useEffect(() => {
    document.title = "Dashboard";
    const handleClickOutsideSidebar = (event) => {
      if (
        billingTypeRef.current &&
        !billingTypeRef.current.contains(event.target)
      ) {
        setShowBillingTypeList(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideSidebar);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideSidebar);
    };
  }, []);
  useEffect(() => {
    const handleClickOutsideSidebar = (event) => {
      if (
        billingStateRef.current &&
        !billingStateRef.current.contains(event.target)
      ) {
        setShowBillingStateList(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideSidebar);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideSidebar);
    };
  }, []);
  useEffect(() => {
    const handleClickOutsideSidebar = (event) => {
      if (
        billingCountryRef.current &&
        !billingCountryRef.current.contains(event.target)
      ) {
        setShowBillingCountryList(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideSidebar);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideSidebar);
    };
  }, []);

  const handleCopyClick = (textToCopy, key) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopyText("Copied!");
        setCopyTextKey(key);
        setTimeout(() => {
          setCopyText("Copy");
          setCopyTextKey(0);
        }, 3000);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  useEffect(() => {
    if (!accessToken) {
      navigate("/");
    } else {
      fetchProfile();
      fetchBillingDetails();
    }
  }, []);

  const fetchProfile = () => {
    setIsLoading(true);
    api
      .get("/profile", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })
      .then((response) => {
        setIsLoading(false);
        if (response.data.success) {
          setProfile(response.data.data);
          setFirstName(response.data.data.first_name);
          setLastName(response.data.data.last_name);
          setEmail(response.data.data.email);
          setMobile(response.data.data.mobile);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const fetchBillingDetails = () => {
    setIsLoading(true);
    api
      .get("/billing", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })
      .then((response) => {
        if (response.data.success) {
          fetchCountries(response.data.data.country_id);
          fetchStates(
            response.data.data.country_id,
            response.data.data.state_id
          );
          setSelectedBillingType(response.data.data.type);
          setBillingFullName(response.data.data.billing_name);
          setBillingAddress(response.data.data.address1);
          setBillingAddress2(response.data.data.address2);
          setBillingCity(response.data.data.city);
          setBillingPincode(response.data.data.pincode);
          if (response.data.data.tax_id != null) {
            setShowTaxIdField(true);
          }
          setBillingTaxId(response.data.data.tax_id);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const fetchCountries = (countryId = null) => {
    setIsLoading(true);
    api
      .get("/countries", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })
      .then((response) => {
        setIsLoading(false);
        if (response.data.success) {
          if (countryId != null) {
            setSelectedBillingCountryId(countryId);
            const selectedCountry = response.data.data.filter(
              (item) => item.id == countryId
            );

            setSelectedBillingCountry(selectedCountry[0].name);
          }
          setBillingCountryList(response.data.data);
          setFilteredBillingCountryList(response.data.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const fetchStates = (countryId, stateId = null) => {
    setIsLoading(true);
    api
      .get(`/countries/${countryId}/states`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })
      .then((response) => {
        setIsLoading(false);
        if (response.data.success) {
          if (stateId != null) {
            setSelectedBillingStateId(stateId);
            const selectedState = response.data.data.filter(
              (item) => item.id == stateId
            );
            setSelectedBillingState(selectedState[0].name);
          }
          setBillingStateList(response.data.data);
          setFilteredBillingStateList(response.data.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const logout = () => {
    setIsLoading(true);
    api
      .post(
        "/logout",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        localStorage.removeItem("accessToken");
        navigate("/");
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const profileUpdateValidations = () => {
    const errors = {};

    if (!firstName) {
      handleAlertBox("error", "First name is required");
      return false;
    }

    if (!lastName) {
      handleAlertBox("error", "Last name is required");
      return false;
    }

    return true;
  };

  const updateProfile = () => {
    const validationError = profileUpdateValidations();
    if (validationError) {
      setIsLoading(true);
      const payload = {
        first_name: firstName,
        last_name: lastName,
      };
      api
        .post("/profile", payload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        })
        .then((response) => {
          if (response.data.success) {
            setIsLoading(false);
            setErrors({});
            handleAlertBox("success", response.data.message);
          } else {
            setIsLoading(false);

            handleAlertBox("error", response.data.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  const changePasswordValidations = () => {
    if (!currentPassword) {
      handleAlertBox("error", "Current password is required");
      return false;
    }

    if (!newPassword) {
      handleAlertBox("error", "New password is required");
      return false;
    }

    if (!confirmNewPasword) {
      handleAlertBox("error", "Password confirmation is required");
      return false;
    } else if (confirmNewPasword != newPassword) {
      handleAlertBox("error", "New password confirmation does not match.");
      return false;
    }

    return true;
  };

  const changePassword = () => {
    const validationError = changePasswordValidations();
    if (validationError) {
      setIsLoading(true);
      const payload = {
        password: currentPassword,
        new_password: newPassword,
        confirm_new_password: confirmNewPasword,
      };
      api
        .post("/profile/password", payload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        })
        .then((response) => {
          if (response.data.success) {
            setIsLoading(false);
            setErrors({});
            handleAlertBox("success", response.data.message);
            setCurrentPassword("");
            setNewPassword("");
            setConfirmNewPassword("");
          } else {
            handleAlertBox("error", response.data.message);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  const billingDetailsValidations = () => {
    if (!selectedBillingType) {
      handleAlertBox("error", "Billing type is required");
      return false;
    }

    if (!billingFullName) {
      handleAlertBox("error", "Full name is required");
      return false;
    }

    if (!billingAddress) {
      handleAlertBox("error", "Address is required");
      return false;
    }

    if (!billingCity) {
      handleAlertBox("error", "City is required");
      return false;
    }

    if (!selectedBillingCountry) {
      handleAlertBox("error", "Country is required");
      return false;
    }

    if (!selectedBillingState) {
      handleAlertBox("error", "State is required");
      return false;
    }

    if (!billingPincode) {
      handleAlertBox("error", "Pincode is required");
      return false;
    }

    return true;
  };

  const saveBillingDetails = () => {
    const validationError = billingDetailsValidations();
    if (validationError) {
      setIsLoading(true);
      const payload = {
        type:
          selectedBillingType == "limited_liability_company"
            ? "llc"
            : selectedBillingType,
        full_name: billingFullName,
        address1: billingAddress,
        address2: billingAddress2,
        city: billingCity,
        country: selectedBillingCountryId,
        state: selectedBillingStateId,
        pincode: billingPincode,
        tax_id: showTaxIdField ? billingTaxId : "",
      };
      api
        .post("/billing", payload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        })
        .then((response) => {
          if (response.data.success) {
            setIsLoading(false);
            setErrors({});
            handleAlertBox("success", response.data.message);
          } else {
            setIsLoading(false);

            handleAlertBox("error", response.data.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  const handleAlertBox = (type, message) => {
    setShowAlertBox(type);
    setAlertText(message);

    setTimeout(() => {
      setShowAlertBox("");
    }, 3000);
  };

  const handleProfileButtonClick = (value) => {
    document.title =
      value == "payment_methods" ? "Payment Methods" : formatString(value);
    setSelectedMenuItem(value);
    setSelectedComponent("profile_menu");
    setShowProfileMenu(false);
    setShowAlertBox("");
  };

  const handleLeftSidebarClick = (value) => {
    document.title = formatString(value);
    setSelectedComponent(value);
    setShowSidebar(false);
    setShowAlertBox("");
  };

  const handleProfileMenuClick = (value) => {
    document.title =
      value == "payment_methods" ? "Payment Methods" : formatString(value);
    setSelectedMenuItem(value);
    setSelectedComponent("profile_menu");
    setShowAlertBox("");
  };

  const handleBillingCountryChange = (value) => {
    setSelectedBillingCountry(value);
    setSelectedBillingState("");
    const filteredList = billingCountryList.filter(
      (item) => item.name && item.name.toLowerCase().includes(value)
    );
    setFilteredBillingCountryList(filteredList);
  };

  const selectBillingCountry = (value) => {
    setSelectedBillingCountryId(value.id);
    setSelectedBillingCountry(value.name);
    setShowBillingCountryList(false);
    setSelectedBillingState("");
    fetchStates(value.id);
  };

  const handleBillingStateChange = (value) => {
    setSelectedBillingState(value);
    const filteredStates = billingStateList.filter(
      (item) => item.name && item.name.toLowerCase().includes(value)
    );
    setFilteredBillingStateList(filteredStates);
  };

  const selectBillingState = (value) => {
    setSelectedBillingStateId(value.id);
    setSelectedBillingState(value.name);
    setShowBillingStateList(false);
  };
  return (
    <>
      {isLoading && <Loader />}
      <div className="mob-head">
        <div className="mob-dashboard-header">
          <div className="mob-header-left">
            <div className="mob-menu-icon">
              <img
                src={hamburger}
                alt=""
                onClick={() => setShowSidebar(true)}
              />
            </div>
            <div className="mob-header-logo">
              <img src={darkLogo} alt="logo" />
            </div>
          </div>
          <div className="mob-header-right">
            <div className="notification-icon">
              <img src={bell} alt="logo" />
              <div className="notification-count">1</div>
            </div>
            <div className="mob-profile-pic-sec">
              <div
                className="profile-pic"
                onClick={() => setShowMobileProfileMenu(!showMobileProfileMenu)}
              >
                H
                {showMobileProfileMenu && (
                  <div className="table-action-menu" ref={dropdownRef}>
                    <div
                      className="action-menu-item"
                      onClick={() => handleProfileMenuClick("profile_details")}
                    >
                      Profile Details
                    </div>
                    <div
                      className="action-menu-item"
                      onClick={() => handleProfileMenuClick("change_password")}
                    >
                      Change Password
                    </div>
                    <div
                      className="action-menu-item"
                      onClick={() => handleProfileMenuClick("billing_details")}
                    >
                      Billing Details
                    </div>
                    <div
                      className="action-menu-item"
                      onClick={() => handleProfileMenuClick("payment_methods")}
                    >
                      Payment Methods
                    </div>
                    <div
                      className="action-menu-item"
                      onClick={() =>
                        handleProfileMenuClick("transaction_history")
                      }
                    >
                      Transaction History
                    </div>
                    <div
                      className="action-menu-item"
                      onClick={() =>
                        handleProfileMenuClick("notifications_settings")
                      }
                    >
                      Notifications Settings
                    </div>
                    <div className="action-menu-item" onClick={() => logout()}>
                      Logout
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="right-upper-input mob">
        <div className="input-search-icon">
          <img src={search} alt="logo" />
        </div>
        <input
          type="text"
          autoComplete="off"
          name="resource"
          placeholder="Search for Resources"
        />
      </div>
      <div className="dashboard">
        <div className="inner-dashboard">
          <div
            className={
              showSidebar
                ? "left-section-overlay"
                : "left-section-overlay sidebar"
            }
          ></div>

          <div
            className={showSidebar ? "left-section" : "left-section sidebar"}
            ref={sidebarRef}
          >
            <div className="left-upper-section">
              <div className="dashboard-logo">
                <img src={logo} alt="logo" />
                <img
                  className="mob-sidebar-cross"
                  src={cross}
                  alt="logo"
                  onClick={() => setShowSidebar(false)}
                />
              </div>
              <div className="left-nav-bar">
                <div
                  className={
                    selectedComponent == "dashboard"
                      ? "left-nav-bar-item selected-nav-bar-item"
                      : "left-nav-bar-item"
                  }
                  onClick={() => handleLeftSidebarClick("dashboard")}
                >
                  <div className="left-nav-bar-icon">
                    <img src={home} alt="logo" />
                  </div>
                  <div className="left-nav-bar-text">Dashboard</div>
                </div>
                <div
                  className={
                    selectedComponent == "servers"
                      ? "left-nav-bar-item selected-nav-bar-item"
                      : "left-nav-bar-item"
                  }
                  onClick={() => handleLeftSidebarClick("servers")}
                >
                  <div className="left-nav-bar-icon">
                    <img src={database} alt="logo" />
                  </div>
                  <div className="left-nav-bar-text">Servers</div>
                </div>
                <div
                  className={
                    selectedComponent == "storage"
                      ? "left-nav-bar-item selected-nav-bar-item"
                      : "left-nav-bar-item"
                  }
                  onClick={() => handleLeftSidebarClick("storage")}
                >
                  <div className="left-nav-bar-icon">
                    <img src={hdd} alt="logo" />
                  </div>
                  <div className="left-nav-bar-text">Storage</div>
                </div>
                <div
                  className={
                    selectedComponent == "snapshots"
                      ? "left-nav-bar-item selected-nav-bar-item"
                      : "left-nav-bar-item"
                  }
                  onClick={() => handleLeftSidebarClick("snapshots")}
                >
                  <div className="left-nav-bar-icon">
                    <img src={snapshot} alt="logo" />
                  </div>
                  <div className="left-nav-bar-text">Snapshots</div>
                </div>
                <div
                  className={
                    selectedComponent == "backups"
                      ? "left-nav-bar-item selected-nav-bar-item"
                      : "left-nav-bar-item"
                  }
                  onClick={() => handleLeftSidebarClick("backups")}
                >
                  <div className="left-nav-bar-icon">
                    <img src={backup} alt="logo" />
                  </div>
                  <div className="left-nav-bar-text">Backups</div>
                </div>
              </div>
            </div>
            <div className="left-lower-section">
              <div className="left-nav-bar-item">
                <div className="left-nav-bar-icon">
                  <img src={support} alt="logo" />
                </div>
                <div className="left-nav-bar-text">
                  <a href="https://www.support.com/" target="_blank">
                    Support
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="right-section">
            <div className="right-upper-section">
              <div className="right-upper-input">
                <div className="input-search-icon">
                  <img src={search} alt="logo" />
                </div>
                <input
                  type="search"
                  id="search"
                  name="search"
                  autoComplete="off"
                  placeholder="Search for Resources"
                />
              </div>
              <div className="profile-notification">
                <div className="notification-icon">
                  <img src={bell} alt="logo" />
                  <div className="notification-count">1</div>
                </div>
                <div
                  className="profile-section"
                  onClick={() => setShowProfileMenu(!showProfileMenu)}
                >
                  <div className="profile-pic">H</div>
                  <div className="profile-text">Harish</div>
                  <div className="arrow-down">
                    <img src={arrowDown} alt="logo" />
                  </div>
                </div>
                {showProfileMenu && (
                  <div className="table-action-menu" ref={dropdownRef}>
                    <div
                      className="action-menu-item"
                      onClick={() =>
                        handleProfileButtonClick("profile_details")
                      }
                    >
                      Profile Details
                    </div>
                    <div
                      className="action-menu-item"
                      onClick={() =>
                        handleProfileButtonClick("change_password")
                      }
                    >
                      Change Password
                    </div>
                    <div
                      className="action-menu-item"
                      onClick={() =>
                        handleProfileButtonClick("billing_details")
                      }
                    >
                      Billing Details
                    </div>
                    <div
                      className="action-menu-item"
                      onClick={() =>
                        handleProfileButtonClick("payment_methods")
                      }
                    >
                      Payment Methods
                    </div>
                    <div
                      className="action-menu-item"
                      onClick={() =>
                        handleProfileButtonClick("transaction_history")
                      }
                    >
                      Transaction History
                    </div>
                    <div
                      className="action-menu-item"
                      onClick={() =>
                        handleProfileButtonClick("notifications_settings")
                      }
                    >
                      Notifications Settings
                    </div>
                    <div className="action-menu-item" onClick={() => logout()}>
                      Logout
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="right-lower-section">
              {selectedComponent == "servers" && (
                <div className="servers-section">
                  <div className="servers-head">
                    <div className="lower-right-heading">Servers (6)</div>
                    <div className="create-server-btn">
                      <div className="solid-btn">
                        <img src={lightPlus} alt="" /> Create Server
                      </div>
                      <div className="mob-add-server-btn">
                        <img src={lightPlus} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="mob-server-list">
                    <div className="mob-server-card">
                      <div className="server-card-left">
                        <div className="server-td">
                          <div className="server-td-left">
                            <img src={window} alt="" />
                          </div>
                          <div className="server-td-right">
                            <div className="text-link">Server Name 1</div>
                            <div className="server-desc">
                              Windows 10 | 8GB | 8vCPU
                            </div>
                          </div>
                        </div>
                        <div className="mob-created-text">
                          Created 1 month ago
                        </div>
                        <div className="ip-address-td">
                          198.168.20.57
                          <span>
                            <img src={copy} alt="" />
                          </span>
                        </div>
                        <div className="grey-badge">Tag1</div>
                      </div>
                      <div className="server-card-right">
                        <div className="server-card-right-top">
                          <div className="success-badge">Running</div>
                          <div className="grey-badge">Monthly</div>
                        </div>
                        <div className="server-card-right-bottom">
                          <div
                            className="table-data-actions"
                            onClick={() => (
                              setShowActionMenu(!showActionMenu),
                              setActionMenu(1)
                            )}
                          >
                            <img src={menuDots} alt="" />
                          </div>
                          {showActionMenu && actionMenu == 1 && (
                            <div className="table-action-menu">
                              <div className="action-menu-item">Edit</div>
                              <div className="action-menu-item">Delete</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mob-server-card">
                      <div className="server-card-left">
                        <div className="server-td">
                          <div className="server-td-left">
                            <img src={ubuntu} alt="" />
                          </div>
                          <div className="server-td-right">
                            <div className="text-link">Server Name 2</div>
                            <div className="server-desc">
                              Ubuntu 20.04 | 8GB | 8vCPU
                            </div>
                          </div>
                        </div>
                        <div className="mob-created-text">
                          Created 1 month ago
                        </div>
                        <div className="ip-address-td">
                          198.168.20.58
                          <span>
                            <img src={copy} alt="" />
                          </span>
                        </div>
                        <div className="grey-badge">Tag1</div>
                      </div>
                      <div className="server-card-right">
                        <div className="server-card-right-top">
                          <div className="success-badge">Running</div>
                          <div className="grey-badge">Monthly</div>
                        </div>
                        <div className="server-card-right-bottom">
                          <div
                            className="table-data-actions"
                            onClick={() => (
                              setShowActionMenu(!showActionMenu),
                              setActionMenu(2)
                            )}
                          >
                            <img src={menuDots} alt="" />
                          </div>
                          {showActionMenu && actionMenu == 2 && (
                            <div className="table-action-menu">
                              <div className="action-menu-item">Edit</div>
                              <div className="action-menu-item">Delete</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mob-server-card">
                      <div className="server-card-left">
                        <div className="server-td">
                          <div className="server-td-left">
                            <img src={window} alt="" />
                          </div>
                          <div className="server-td-right">
                            <div className="text-link">Server Name 3</div>
                            <div className="server-desc">
                              Windows 10 | 8GB | 8vCPU
                            </div>
                          </div>
                        </div>
                        <div className="mob-created-text">
                          Created 2 month ago
                        </div>
                        <div className="ip-address-td">
                          198.168.20.59
                          <span>
                            <img src={copy} alt="" />
                          </span>
                        </div>
                        <div className="grey-badge">Tag1</div>
                      </div>
                      <div className="server-card-right">
                        <div className="server-card-right-top">
                          <div className="success-badge">Running</div>
                          <div className="grey-badge">Monthly</div>
                        </div>
                        <div className="server-card-right-bottom">
                          <div
                            className="table-data-actions"
                            onClick={() => (
                              setShowActionMenu(!showActionMenu),
                              setActionMenu(3)
                            )}
                          >
                            <img src={menuDots} alt="" />
                          </div>
                          {showActionMenu && actionMenu == 3 && (
                            <div className="table-action-menu">
                              <div className="action-menu-item">Edit</div>
                              <div className="action-menu-item">Delete</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mob-server-card">
                      <div className="server-card-left">
                        <div className="server-td">
                          <div className="server-td-left">
                            <img src={ubuntu} alt="" />
                          </div>
                          <div className="server-td-right">
                            <div className="text-link">Server Name 1</div>
                            <div className="server-desc">
                              Ubuntu 24.04 | 8GB | 8vCPU
                            </div>
                          </div>
                        </div>
                        <div className="mob-created-text">
                          Created 4 month ago
                        </div>
                        <div className="ip-address-td">
                          198.168.20.60
                          <span>
                            <img src={copy} alt="" />
                          </span>
                        </div>
                        <div className="grey-badge">Tag1</div>
                      </div>
                      <div className="server-card-right">
                        <div className="server-card-right-top">
                          <div className="danger-badge">Stopped</div>
                          <div className="grey-badge">Monthly</div>
                        </div>
                        <div className="server-card-right-bottom">
                          <div
                            className="table-data-actions"
                            onClick={() => (
                              setShowActionMenu(!showActionMenu),
                              setActionMenu(4)
                            )}
                          >
                            <img src={menuDots} alt="" />
                          </div>
                          {showActionMenu && actionMenu == 4 && (
                            <div className="table-action-menu">
                              <div className="action-menu-item">Edit</div>
                              <div className="action-menu-item">Delete</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mob-server-card">
                      <div className="server-card-left">
                        <div className="server-td">
                          <div className="server-td-left">
                            <img src={window} alt="" />
                          </div>
                          <div className="server-td-right">
                            <div className="text-link">Server Name 5</div>
                            <div className="server-desc">
                              Windows 10 | 8GB | 8vCPU
                            </div>
                          </div>
                        </div>
                        <div className="mob-created-text">
                          Created 6 month ago
                        </div>
                        <div className="ip-address-td">
                          198.168.20.61
                          <span>
                            <img src={copy} alt="" />
                          </span>
                        </div>
                        <div className="grey-badge">Tag1</div>
                      </div>
                      <div className="server-card-right">
                        <div className="server-card-right-top">
                          <div className="success-badge">Running</div>
                          <div className="grey-badge">Monthly</div>
                        </div>
                        <div className="server-card-right-bottom">
                          <div
                            className="table-data-actions"
                            onClick={() => (
                              setShowActionMenu(!showActionMenu),
                              setActionMenu(5)
                            )}
                          >
                            <img src={menuDots} alt="" />
                          </div>
                          {showActionMenu && actionMenu == 5 && (
                            <div className="table-action-menu">
                              <div className="action-menu-item">Edit</div>
                              <div className="action-menu-item">Delete</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mob-server-card">
                      <div className="server-card-left">
                        <div className="server-td">
                          <div className="server-td-left">
                            <img src={window} alt="" />
                          </div>
                          <div className="server-td-right">
                            <div className="text-link">Server Name 6</div>
                            <div className="server-desc">
                              Windows 10 | 8GB | 8vCPU
                            </div>
                          </div>
                        </div>
                        <div className="mob-created-text">
                          Created 8 month ago
                        </div>
                        <div className="ip-address-td">
                          198.168.20.62
                          <span>
                            <img src={copy} alt="" />
                          </span>
                        </div>
                        <div className="grey-badge">Tag1</div>
                      </div>
                      <div className="server-card-right">
                        <div className="server-card-right-top">
                          <div className="success-badge">Running</div>
                          <div className="grey-badge">Monthly</div>
                        </div>
                        <div className="server-card-right-bottom">
                          <div
                            className="table-data-actions"
                            onClick={() => (
                              setShowActionMenu(!showActionMenu),
                              setActionMenu(6)
                            )}
                          >
                            <img src={menuDots} alt="" />
                          </div>
                          {showActionMenu && actionMenu == 6 && (
                            <div className="table-action-menu">
                              <div className="action-menu-item">Edit</div>
                              <div className="action-menu-item">Delete</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="transaction-history-table">
                    <div className="history-table-row table-head">
                      <div
                        className="history-table-data"
                        style={{ width: "25%" }}
                      >
                        Server
                      </div>
                      <div
                        className="history-table-data"
                        style={{ width: "15%" }}
                      >
                        Status
                      </div>
                      <div
                        className="history-table-data"
                        style={{ width: "25%" }}
                      >
                        IP Address
                      </div>
                      <div
                        className="history-table-data"
                        style={{ width: "10%" }}
                      >
                        Billing
                      </div>
                      <div
                        className="history-table-data"
                        style={{ width: "10%" }}
                      >
                        Tags
                      </div>

                      <div
                        className="history-table-data"
                        style={{ width: "15%" }}
                      ></div>
                      <div
                        className="history-table-data"
                        style={{ width: "5%" }}
                      ></div>
                    </div>
                    <div className="transaction-history-body">
                      <div className="history-table-row">
                        <div
                          className="history-table-data"
                          style={{ width: "25%" }}
                        >
                          <div className="server-td">
                            <div className="server-td-left">
                              <img src={window} alt="" />
                            </div>
                            <div className="server-td-right">
                              <div className="text-link">Server Name 1</div>
                              <div className="server-desc">
                                Windows 10 | 8GB | 8vCPU
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="history-table-data"
                          style={{ width: "15%" }}
                        >
                          <div className="success-badge">Running</div>
                        </div>
                        <div
                          className="history-table-data"
                          style={{ width: "25%" }}
                        >
                          <div className="ip-address-td">
                            198.168.20.57
                            <span>
                              <img
                                src={copy}
                                alt=""
                                onClick={() =>
                                  handleCopyClick("198.168.20.57", 1)
                                }
                              />
                            </span>
                            <span
                              className="text-link"
                              style={{
                                color:
                                  copyText == "Copied!" && copyTextKey == 1
                                    ? "#4CAF50"
                                    : "",
                              }}
                              onClick={() =>
                                handleCopyClick("198.168.20.57", 1)
                              }
                            >
                              {copyTextKey == 1 ? copyText : "Copy"}
                            </span>
                          </div>
                        </div>
                        <div
                          className="history-table-data"
                          style={{ width: "10%" }}
                        >
                          <div className="grey-badge">Monthly</div>
                        </div>
                        <div
                          className="history-table-data"
                          style={{ width: "10%" }}
                        >
                          <div className="grey-badge">Tag1</div>
                        </div>
                        <div
                          className="history-table-data"
                          style={{ width: "15%" }}
                        >
                          Created 1 month ago
                        </div>
                        <div
                          className="history-table-data"
                          style={{
                            width: "5%",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div
                            className="table-data-actions"
                            onClick={() => (
                              setShowActionMenu(!showActionMenu),
                              setActionMenu(1)
                            )}
                          >
                            <img src={menuDots} alt="" />
                          </div>
                          {showActionMenu && actionMenu == 1 && (
                            <div className="table-action-menu">
                              <div className="action-menu-item">Edit</div>
                              <div className="action-menu-item">Delete</div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="history-table-row">
                        <div
                          className="history-table-data"
                          style={{ width: "25%" }}
                        >
                          <div className="server-td">
                            <div className="server-td-left">
                              <img src={ubuntu} alt="" />
                            </div>
                            <div className="server-td-right">
                              <div className="text-link">Server Name 2</div>
                              <div className="server-desc">
                                ubuntu 24.04 | 8GB | 8vCPU
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="history-table-data"
                          style={{ width: "15%" }}
                        >
                          <div className="success-badge">Running</div>
                        </div>
                        <div
                          className="history-table-data"
                          style={{ width: "25%" }}
                        >
                          <div className="ip-address-td">
                            198.168.20.58
                            <span>
                              <img
                                src={copy}
                                alt=""
                                onClick={() =>
                                  handleCopyClick("198.168.20.58", 2)
                                }
                              />
                            </span>
                            <span
                              className="text-link"
                              style={{
                                color:
                                  copyText == "Copied!" && copyTextKey == 2
                                    ? "#4CAF50"
                                    : "",
                              }}
                              onClick={() =>
                                handleCopyClick("198.168.20.58", 2)
                              }
                            >
                              {copyTextKey == 2 ? copyText : "Copy"}
                            </span>
                          </div>
                        </div>
                        <div
                          className="history-table-data"
                          style={{ width: "10%" }}
                        >
                          <div className="grey-badge">Monthly</div>
                        </div>
                        <div
                          className="history-table-data"
                          style={{ width: "10%" }}
                        >
                          <div className="grey-badge">Tag1</div>
                        </div>
                        <div
                          className="history-table-data"
                          style={{ width: "15%" }}
                        >
                          Created 1 month ago
                        </div>
                        <div
                          className="history-table-data"
                          style={{
                            width: "5%",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div
                            className="table-data-actions"
                            onClick={() => (
                              setShowActionMenu(!showActionMenu),
                              setActionMenu(2)
                            )}
                          >
                            <img src={menuDots} alt="" />
                          </div>
                          {showActionMenu && actionMenu == 2 && (
                            <div className="table-action-menu">
                              <div className="action-menu-item">Edit</div>
                              <div className="action-menu-item">Delete</div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="history-table-row">
                        <div
                          className="history-table-data"
                          style={{ width: "25%" }}
                        >
                          <div className="server-td">
                            <div className="server-td-left">
                              <img src={window} alt="" />
                            </div>
                            <div className="server-td-right">
                              <div className="text-link">Server Name 3</div>
                              <div className="server-desc">
                                Windows 10 | 8GB | 8vCPU
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="history-table-data"
                          style={{ width: "15%" }}
                        >
                          <div className="success-badge">Running</div>
                        </div>
                        <div
                          className="history-table-data"
                          style={{ width: "25%" }}
                        >
                          <div className="ip-address-td">
                            198.168.20.59
                            <span>
                              <img
                                src={copy}
                                alt=""
                                onClick={() =>
                                  handleCopyClick("198.168.20.59", 3)
                                }
                              />
                            </span>
                            <span
                              className="text-link"
                              style={{
                                color:
                                  copyText == "Copied!" && copyTextKey == 3
                                    ? "#4CAF50"
                                    : "",
                              }}
                              onClick={() =>
                                handleCopyClick("198.168.20.59", 3)
                              }
                            >
                              {copyTextKey == 3 ? copyText : "Copy"}
                            </span>
                          </div>
                        </div>
                        <div
                          className="history-table-data"
                          style={{ width: "10%" }}
                        >
                          <div className="grey-badge">Monthly</div>
                        </div>
                        <div
                          className="history-table-data"
                          style={{ width: "10%" }}
                        >
                          <div className="grey-badge">Tag1</div>
                        </div>
                        <div
                          className="history-table-data"
                          style={{ width: "15%" }}
                        >
                          Created 3 month ago
                        </div>
                        <div
                          className="history-table-data"
                          style={{
                            width: "5%",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div
                            className="table-data-actions"
                            onClick={() => (
                              setShowActionMenu(!showActionMenu),
                              setActionMenu(3)
                            )}
                          >
                            <img src={menuDots} alt="" />
                          </div>
                          {showActionMenu && actionMenu == 3 && (
                            <div className="table-action-menu">
                              <div className="action-menu-item">Edit</div>
                              <div className="action-menu-item">Delete</div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="history-table-row">
                        <div
                          className="history-table-data"
                          style={{ width: "25%" }}
                        >
                          <div className="server-td">
                            <div className="server-td-left">
                              <img src={window} alt="" />
                            </div>
                            <div className="server-td-right">
                              <div className="text-link">Server Name 4</div>
                              <div className="server-desc">
                                Windows 10 | 8GB | 8vCPU
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="history-table-data"
                          style={{ width: "15%" }}
                        >
                          <div className="success-badge">Running</div>
                        </div>
                        <div
                          className="history-table-data"
                          style={{ width: "25%" }}
                        >
                          <div className="ip-address-td">
                            198.168.20.60
                            <span>
                              <img
                                src={copy}
                                alt=""
                                onClick={() =>
                                  handleCopyClick("198.168.20.60", 4)
                                }
                              />
                            </span>
                            <span
                              className="text-link"
                              style={{
                                color:
                                  copyText == "Copied!" && copyTextKey == 4
                                    ? "#4CAF50"
                                    : "",
                              }}
                              onClick={() =>
                                handleCopyClick("198.168.20.60", 4)
                              }
                            >
                              {copyTextKey == 4 ? copyText : "Copy"}
                            </span>
                          </div>
                        </div>
                        <div
                          className="history-table-data"
                          style={{ width: "10%" }}
                        >
                          <div className="grey-badge">Monthly</div>
                        </div>
                        <div
                          className="history-table-data"
                          style={{ width: "10%" }}
                        >
                          <div className="grey-badge">Tag1</div>
                        </div>
                        <div
                          className="history-table-data"
                          style={{ width: "15%" }}
                        >
                          Created 5 month ago
                        </div>
                        <div
                          className="history-table-data"
                          style={{
                            width: "5%",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div
                            className="table-data-actions"
                            onClick={() => (
                              setShowActionMenu(!showActionMenu),
                              setActionMenu(4)
                            )}
                          >
                            <img src={menuDots} alt="" />
                          </div>
                          {showActionMenu && actionMenu == 4 && (
                            <div className="table-action-menu">
                              <div className="action-menu-item">Edit</div>
                              <div className="action-menu-item">Delete</div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="history-table-row">
                        <div
                          className="history-table-data"
                          style={{ width: "25%" }}
                        >
                          <div className="server-td">
                            <div className="server-td-left">
                              <img src={ubuntu} alt="" />
                            </div>
                            <div className="server-td-right">
                              <div className="text-link">Server Name 5</div>
                              <div className="server-desc">
                                Ubuntu 24.04 | 8GB | 8vCPU
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="history-table-data"
                          style={{ width: "15%" }}
                        >
                          <div className="danger-badge">Stopped</div>
                        </div>
                        <div
                          className="history-table-data"
                          style={{ width: "25%" }}
                        >
                          <div className="ip-address-td">
                            198.168.20.61
                            <span>
                              <img
                                src={copy}
                                alt=""
                                onClick={() =>
                                  handleCopyClick("198.168.20.61", 5)
                                }
                              />
                            </span>
                            <span
                              className="text-link"
                              style={{
                                color:
                                  copyText == "Copied!" && copyTextKey == 5
                                    ? "#4CAF50"
                                    : "",
                              }}
                              onClick={() =>
                                handleCopyClick("198.168.20.61", 5)
                              }
                            >
                              {copyTextKey == 5 ? copyText : "Copy"}
                            </span>
                          </div>
                        </div>
                        <div
                          className="history-table-data"
                          style={{ width: "10%" }}
                        >
                          <div className="grey-badge">Monthly</div>
                        </div>
                        <div
                          className="history-table-data"
                          style={{ width: "10%" }}
                        >
                          <div className="grey-badge">Tag1</div>
                        </div>
                        <div
                          className="history-table-data"
                          style={{ width: "15%" }}
                        >
                          Created 7 month ago
                        </div>
                        <div
                          className="history-table-data"
                          style={{
                            width: "5%",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div
                            className="table-data-actions"
                            onClick={() => (
                              setShowActionMenu(!showActionMenu),
                              setActionMenu(5)
                            )}
                          >
                            <img src={menuDots} alt="" />
                          </div>
                          {showActionMenu && actionMenu == 5 && (
                            <div className="table-action-menu">
                              <div className="action-menu-item">Edit</div>
                              <div className="action-menu-item">Delete</div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="history-table-row">
                        <div
                          className="history-table-data"
                          style={{ width: "25%" }}
                        >
                          <div className="server-td">
                            <div className="server-td-left">
                              <img src={ubuntu} alt="" />
                            </div>
                            <div className="server-td-right">
                              <div className="text-link">Server Name 6</div>
                              <div className="server-desc">
                                Ubuntu 20.04 | 8GB | 8vCPU
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="history-table-data"
                          style={{ width: "15%" }}
                        >
                          <div className="danger-badge">Stopped</div>
                        </div>
                        <div
                          className="history-table-data"
                          style={{ width: "25%" }}
                        >
                          <div className="ip-address-td">
                            198.168.20.65
                            <span>
                              <img
                                src={copy}
                                alt=""
                                onClick={() =>
                                  handleCopyClick("198.168.20.65", 6)
                                }
                              />
                            </span>
                            <span
                              className="text-link"
                              style={{
                                color:
                                  copyText == "Copied!" && copyTextKey == 6
                                    ? "#4CAF50"
                                    : "",
                              }}
                              onClick={() =>
                                handleCopyClick("198.168.20.65", 6)
                              }
                            >
                              {copyTextKey == 6 ? copyText : "Copy"}
                            </span>
                          </div>
                        </div>
                        <div
                          className="history-table-data"
                          style={{ width: "10%" }}
                        >
                          <div className="grey-badge">Monthly</div>
                        </div>
                        <div
                          className="history-table-data"
                          style={{ width: "10%" }}
                        >
                          <div className="grey-badge">Tag1</div>
                        </div>
                        <div
                          className="history-table-data"
                          style={{ width: "15%" }}
                        >
                          Created 9 month ago
                        </div>
                        <div
                          className="history-table-data"
                          style={{
                            width: "5%",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div
                            className="table-data-actions"
                            onClick={() => (
                              setShowActionMenu(!showActionMenu),
                              setActionMenu(6)
                            )}
                          >
                            <img src={menuDots} alt="" />
                          </div>
                          {showActionMenu && actionMenu == 6 && (
                            <div className="table-action-menu">
                              <div className="action-menu-item">Edit</div>
                              <div className="action-menu-item">Delete</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {selectedComponent == "profile_menu" && (
                <div className="profile-menu">
                  <div className="right-lower-left-section">
                    <div className="right-menu-bar">
                      <div className="right-menu-bar-head">Menu</div>
                      <div
                        className={
                          selectedMenuItem == "profile_details"
                            ? "right-menu-bar-item selected-menu-item"
                            : "right-menu-bar-item"
                        }
                        onClick={() =>
                          handleProfileMenuClick("profile_details")
                        }
                      >
                        Profile Details
                      </div>
                      <div
                        className={
                          selectedMenuItem == "change_password"
                            ? "right-menu-bar-item selected-menu-item"
                            : "right-menu-bar-item"
                        }
                        onClick={() =>
                          handleProfileMenuClick("change_password")
                        }
                      >
                        Change Password
                      </div>
                      <div
                        className={
                          selectedMenuItem == "billing_details"
                            ? "right-menu-bar-item selected-menu-item"
                            : "right-menu-bar-item"
                        }
                        onClick={() =>
                          handleProfileMenuClick("billing_details")
                        }
                      >
                        Billing Details
                      </div>
                      <div
                        className={
                          selectedMenuItem == "payment_methods"
                            ? "right-menu-bar-item selected-menu-item"
                            : "right-menu-bar-item"
                        }
                        onClick={() =>
                          handleProfileMenuClick("payment_methods")
                        }
                      >
                        Payment Methods
                      </div>
                      <div
                        className={
                          selectedMenuItem == "transaction_history"
                            ? "right-menu-bar-item selected-menu-item"
                            : "right-menu-bar-item"
                        }
                        onClick={() =>
                          handleProfileMenuClick("transaction_history")
                        }
                      >
                        Transaction History
                      </div>
                      <div
                        className={
                          selectedMenuItem == "notifications_settings"
                            ? "right-menu-bar-item selected-menu-item"
                            : "right-menu-bar-item"
                        }
                        onClick={() =>
                          handleProfileMenuClick("notifications_settings")
                        }
                      >
                        Notifications Settings
                      </div>
                    </div>
                  </div>
                  <div className="right-lower-right-section">
                    {showAlertBox != "" && (
                      <div className={"alert-text api-" + showAlertBox}>
                        <div className="alert-text-left">
                          <div className="alert-title">
                            {showAlertBox == "success"
                              ? "Success!"
                              : "Warning!"}
                          </div>
                          <div className="alert-msg">{alertText}</div>
                        </div>
                        <div className="alert-text-right">
                          {showAlertBox == "success" ? (
                            <img
                              src={greenCross}
                              alt=""
                              onClick={() => setShowAlertBox("")}
                            />
                          ) : (
                            <img
                              src={redCross}
                              alt=""
                              onClick={() => setShowAlertBox("")}
                            />
                          )}
                        </div>
                      </div>
                    )}
                    <div className="lower-right-heading">
                      {formatString(selectedMenuItem)}
                    </div>
                    <div className="right-body-section">
                      {selectedMenuItem == "billing_details" && (
                        <div className="billing-details-form">
                          <div className="right-form-upper">
                            <div className="right-form-head">
                              These details will be use to generate future
                              invoices.
                            </div>
                            <div className="right-form-body">
                              <div className="right-form-input-row">
                                <div className="input-box">
                                  <div className="input-label">Biling Type</div>
                                  <div className="input-field">
                                    <input
                                      type="text"
                                      value={
                                        selectedBillingType
                                          ? formatString(selectedBillingType)
                                          : ""
                                      }
                                      onClick={() =>
                                        setShowBillingTypeList(true)
                                      }
                                    />
                                    <div
                                      className="input-arrow-down"
                                      onClick={() =>
                                        setShowBillingTypeList(true)
                                      }
                                    >
                                      <img src={arrowDown} alt="" />
                                    </div>
                                  </div>
                                  {showBillingTypeList && (
                                    <div
                                      className="table-action-menu"
                                      ref={billingTypeRef}
                                    >
                                      {billingTypeList.map((value, i) => {
                                        return (
                                          <div
                                            className="action-menu-item"
                                            key={i}
                                            onClick={() => (
                                              setSelectedBillingType(value),
                                              setShowBillingTypeList(false)
                                            )}
                                          >
                                            {formatString(value)}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                                </div>
                                <div className="input-box">
                                  <div className="input-label">Full Name</div>
                                  <div className="input-field">
                                    <input
                                      type="text"
                                      value={billingFullName}
                                      onChange={(e) => (
                                        setBillingFullName(e.target.value),
                                        setShowAlertBox("")
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="right-form-input-row">
                                <div className="input-box">
                                  <div className="input-label">Address</div>
                                  <div className="input-field">
                                    <input
                                      type="text"
                                      value={billingAddress}
                                      onChange={(e) => (
                                        setBillingAddress(e.target.value),
                                        setShowAlertBox("")
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className="input-box">
                                  <div className="input-label">Address 2</div>
                                  <div className="input-field">
                                    <input
                                      type="text"
                                      value={billingAddress2}
                                      onChange={(e) => (
                                        setBillingAddress2(e.target.value),
                                        setShowAlertBox("")
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="right-form-input-row">
                                <div className="input-box">
                                  <div className="input-label">City</div>
                                  <div className="input-field">
                                    <input
                                      type="text"
                                      value={billingCity}
                                      onChange={(e) => (
                                        setBillingCity(e.target.value),
                                        setShowAlertBox("")
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className="input-box">
                                  <div className="input-label">Country</div>
                                  <div className="input-field">
                                    <input
                                      type="text"
                                      value={selectedBillingCountry}
                                      onChange={(e) =>
                                        handleBillingCountryChange(
                                          e.target.value
                                        )
                                      }
                                      onClick={() =>
                                        setShowBillingCountryList(true)
                                      }
                                    />
                                    <div className="input-arrow-down">
                                      <img
                                        src={arrowDown}
                                        alt=""
                                        onClick={() =>
                                          setShowBillingCountryList(true)
                                        }
                                      />
                                    </div>
                                  </div>
                                  {showBillingCountryList && (
                                    <div
                                      className="table-action-menu"
                                      ref={billingCountryRef}
                                    >
                                      {filteredbillingCountryList.map(
                                        (value, i) => {
                                          return (
                                            <div
                                              className="action-menu-item"
                                              key={i}
                                              onClick={() =>
                                                selectBillingCountry(value)
                                              }
                                            >
                                              {value.name}
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="right-form-input-row">
                                <div className="input-box">
                                  <div className="input-label">State</div>
                                  <div className="input-field">
                                    <input
                                      type="text"
                                      value={selectedBillingState}
                                      onClick={() =>
                                        setShowBillingStateList(true)
                                      }
                                      onChange={(e) =>
                                        handleBillingStateChange(e.target.value)
                                      }
                                      disabled={selectedBillingCountry == ""}
                                    />
                                    <div className="input-arrow-down">
                                      <img src={arrowDown} alt="" />
                                    </div>
                                  </div>
                                  {showBillingStateList && (
                                    <div
                                      className="table-action-menu"
                                      ref={billingStateRef}
                                    >
                                      {filteredbillingStateList.map(
                                        (value, i) => {
                                          return (
                                            <div
                                              className="action-menu-item"
                                              key={i}
                                              onClick={() =>
                                                selectBillingState(value)
                                              }
                                            >
                                              {value.name}
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="input-box">
                                  <div className="input-label">Pincode</div>
                                  <div className="input-field">
                                    <input
                                      type="number"
                                      value={billingPincode}
                                      onChange={(e) => (
                                        setBillingPincode(e.target.value),
                                        setShowAlertBox("")
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                              {!showTaxIdField && (
                                <div className="add-tax-btn">
                                  <div
                                    className="regular-btn"
                                    onClick={() => setShowTaxIdField(true)}
                                  >
                                    Add Tax ID
                                  </div>
                                </div>
                              )}
                              {showTaxIdField && (
                                <div className="tax-id-field">
                                  <div className="right-form-input-row">
                                    <div className="input-box">
                                      <div className="input-label">Tax ID</div>
                                      <div className="input-field">
                                        <input
                                          type="text"
                                          value={billingTaxId}
                                          onChange={(e) => (
                                            setBillingTaxId(e.target.value),
                                            setShowAlertBox("")
                                          )}
                                        />
                                        <div
                                          className="change-text"
                                          onClick={() => (
                                            setShowTaxIdField(false),
                                            setBillingTaxId("")
                                          )}
                                        >
                                          Remove
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="right-form-lower">
                            <div
                              className="right-form-button"
                              onClick={() => saveBillingDetails()}
                            >
                              Save Details
                            </div>
                          </div>
                        </div>
                      )}
                      {selectedMenuItem == "transaction_history" && (
                        <>
                          <div className="mob-transaction-history-list">
                            <div className="mob-server-card">
                              <div className="server-card-left">
                                <span className="text-link">
                                  TI-2024/25/016
                                </span>
                                <div className="ip-address-td">
                                  Invoice for Aug 2024
                                </div>
                                <div className="mob-created-text">
                                  Created 1 month ago
                                </div>
                                <div className="pending-badge">Due</div>
                              </div>
                              <div className="server-card-right">
                                <div className="server-card-right-top">
                                  <p>$2102.08</p>
                                  <p
                                    style={{
                                      color: "#f44336",
                                      fontSize: "10px",
                                    }}
                                  >
                                    $210.20 Discount
                                  </p>
                                </div>
                                <div className="server-card-right-bottom">
                                  <div
                                    className="table-data-actions"
                                    onClick={() => (
                                      setShowActionMenu(!showActionMenu),
                                      setActionMenu(1)
                                    )}
                                  >
                                    <img src={menuDots} alt="" />
                                  </div>
                                  {showActionMenu && actionMenu == 1 && (
                                    <div className="table-action-menu">
                                      <div className="action-menu-item">
                                        Edit
                                      </div>
                                      <div className="action-menu-item">
                                        Delete
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="mob-server-card">
                              <div className="server-card-left">
                                <span className="text-link">
                                  TI-2024/25/016
                                </span>
                                <div className="ip-address-td">
                                  Invoice for Aug 2024
                                </div>
                                <div className="mob-created-text">
                                  Created 1 month ago
                                </div>
                                <div className="success-badge">Paid</div>
                              </div>
                              <div className="server-card-right">
                                <div className="server-card-right-top">
                                  <p>$2102.08</p>
                                  <p
                                    style={{
                                      color: "#f44336",
                                      fontSize: "10px",
                                    }}
                                  >
                                    $210.20 Discount
                                  </p>
                                </div>
                                <div className="server-card-right-bottom">
                                  <div
                                    className="table-data-actions"
                                    onClick={() => (
                                      setShowActionMenu(!showActionMenu),
                                      setActionMenu(2)
                                    )}
                                  >
                                    <img src={menuDots} alt="" />
                                  </div>
                                  {showActionMenu && actionMenu == 2 && (
                                    <div className="table-action-menu">
                                      <div className="action-menu-item">
                                        Edit
                                      </div>
                                      <div className="action-menu-item">
                                        Delete
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="mob-server-card">
                              <div className="server-card-left">
                                <span className="text-link">
                                  TI-2024/25/016
                                </span>
                                <div className="ip-address-td">
                                  Invoice for Aug 2024
                                </div>
                                <div className="mob-created-text">
                                  Created 1 month ago
                                </div>
                                <div className="success-badge">Paid</div>
                              </div>
                              <div className="server-card-right">
                                <div className="server-card-right-top">
                                  <p>$2102.08</p>
                                  <p
                                    style={{
                                      color: "#f44336",
                                      fontSize: "10px",
                                    }}
                                  >
                                    $210.20 Discount
                                  </p>
                                </div>
                                <div className="server-card-right-bottom">
                                  <div
                                    className="table-data-actions"
                                    onClick={() => (
                                      setShowActionMenu(!showActionMenu),
                                      setActionMenu(3)
                                    )}
                                  >
                                    <img src={menuDots} alt="" />
                                  </div>
                                  {showActionMenu && actionMenu == 3 && (
                                    <div className="table-action-menu">
                                      <div className="action-menu-item">
                                        Edit
                                      </div>
                                      <div className="action-menu-item">
                                        Delete
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="mob-server-card">
                              <div className="server-card-left">
                                <span className="text-link">
                                  TI-2024/25/016
                                </span>
                                <div className="ip-address-td">
                                  Invoice for Aug 2024
                                </div>
                                <div className="mob-created-text">
                                  Created 1 month ago
                                </div>
                                <div className="success-badge">Paid</div>
                              </div>
                              <div className="server-card-right">
                                <div className="server-card-right-top">
                                  <p>$2102.08</p>
                                  <p
                                    style={{
                                      color: "#f44336",
                                      fontSize: "10px",
                                    }}
                                  >
                                    $210.20 Discount
                                  </p>
                                </div>
                                <div className="server-card-right-bottom">
                                  <div
                                    className="table-data-actions"
                                    onClick={() => (
                                      setShowActionMenu(!showActionMenu),
                                      setActionMenu(4)
                                    )}
                                  >
                                    <img src={menuDots} alt="" />
                                  </div>
                                  {showActionMenu && actionMenu == 4 && (
                                    <div className="table-action-menu">
                                      <div className="action-menu-item">
                                        Edit
                                      </div>
                                      <div className="action-menu-item">
                                        Delete
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="mob-server-card">
                              <div className="server-card-left">
                                <span className="text-link">
                                  TI-2024/25/016
                                </span>
                                <div className="ip-address-td">
                                  Invoice for Aug 2024
                                </div>
                                <div className="mob-created-text">
                                  Created 1 month ago
                                </div>
                                <div className="success-badge">Paid</div>
                              </div>
                              <div className="server-card-right">
                                <div className="server-card-right-top">
                                  <p>$2102.08</p>
                                  <p
                                    style={{
                                      color: "#f44336",
                                      fontSize: "10px",
                                    }}
                                  >
                                    $210.20 Discount
                                  </p>
                                </div>
                                <div className="server-card-right-bottom">
                                  <div
                                    className="table-data-actions"
                                    onClick={() => (
                                      setShowActionMenu(!showActionMenu),
                                      setActionMenu(5)
                                    )}
                                  >
                                    <img src={menuDots} alt="" />
                                  </div>
                                  {showActionMenu && actionMenu == 5 && (
                                    <div className="table-action-menu">
                                      <div className="action-menu-item">
                                        Edit
                                      </div>
                                      <div className="action-menu-item">
                                        Delete
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="mob-server-card">
                              <div className="server-card-left">
                                <span className="text-link">
                                  TI-2024/25/016
                                </span>
                                <div className="ip-address-td">
                                  Invoice for Aug 2024
                                </div>
                                <div className="mob-created-text">
                                  Created 1 month ago
                                </div>
                                <div className="success-badge">Paid</div>
                              </div>
                              <div className="server-card-right">
                                <div className="server-card-right-top">
                                  <p>$2102.08</p>
                                  <p
                                    style={{
                                      color: "#f44336",
                                      fontSize: "10px",
                                    }}
                                  >
                                    $210.20 Discount
                                  </p>
                                </div>
                                <div className="server-card-right-bottom">
                                  <div
                                    className="table-data-actions"
                                    onClick={() => (
                                      setShowActionMenu(!showActionMenu),
                                      setActionMenu(6)
                                    )}
                                  >
                                    <img src={menuDots} alt="" />
                                  </div>
                                  {showActionMenu && actionMenu == 6 && (
                                    <div className="table-action-menu">
                                      <div className="action-menu-item">
                                        Edit
                                      </div>
                                      <div className="action-menu-item">
                                        Delete
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="transaction-history-table">
                            <div className="history-table-row table-head">
                              <div
                                className="history-table-data"
                                style={{ width: "20%" }}
                              >
                                Invoice No.
                              </div>
                              <div
                                className="history-table-data"
                                style={{ width: "25%" }}
                              >
                                Description
                              </div>
                              <div
                                className="history-table-data"
                                style={{ width: "15%" }}
                              >
                                Amount
                              </div>
                              <div
                                className="history-table-data"
                                style={{ width: "15%" }}
                              >
                                Status
                              </div>
                              <div
                                className="history-table-data"
                                style={{ width: "20%" }}
                              ></div>
                              <div
                                className="history-table-data"
                                style={{ width: "5%" }}
                              ></div>
                            </div>
                            <div className="transaction-history-body">
                              <div className="history-table-row">
                                <div
                                  className="history-table-data"
                                  style={{ width: "20%" }}
                                >
                                  <span className="text-link">
                                    TI-2024/25/016
                                  </span>
                                </div>
                                <div
                                  className="history-table-data"
                                  style={{ width: "25%" }}
                                >
                                  Invoice for Sep 2024
                                </div>
                                <div
                                  className="history-table-data"
                                  style={{ width: "15%" }}
                                >
                                  <p>$2102.08</p>
                                  <p
                                    style={{
                                      color: "#f44336",
                                      fontSize: "10px",
                                    }}
                                  >
                                    $210.20 Discount
                                  </p>
                                </div>
                                <div
                                  className="history-table-data"
                                  style={{ width: "15%" }}
                                >
                                  <div className="success-badge">Paid</div>
                                </div>
                                <div
                                  className="history-table-data"
                                  style={{ width: "20%" }}
                                >
                                  Created 1 month ago
                                </div>
                                <div
                                  className="history-table-data"
                                  style={{
                                    width: "5%",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <div
                                    className="table-data-actions"
                                    onClick={() => (
                                      setShowActionMenu(!showActionMenu),
                                      setActionMenu(1)
                                    )}
                                  >
                                    <img src={menuDots} alt="" />
                                  </div>
                                  {showActionMenu && actionMenu == 1 && (
                                    <div className="table-action-menu">
                                      <div className="action-menu-item">
                                        Edit
                                      </div>
                                      <div className="action-menu-item">
                                        Delete
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="history-table-row">
                                <div
                                  className="history-table-data"
                                  style={{ width: "20%" }}
                                >
                                  <span className="text-link">
                                    TI-2024/25/016
                                  </span>
                                </div>
                                <div
                                  className="history-table-data"
                                  style={{ width: "25%" }}
                                >
                                  Invoice for Sep 2024
                                </div>
                                <div
                                  className="history-table-data"
                                  style={{ width: "15%" }}
                                >
                                  <p>$2102.08</p>
                                  <p
                                    style={{
                                      color: "#f44336",
                                      fontSize: "10px",
                                    }}
                                  >
                                    $210.20 Discount
                                  </p>
                                </div>
                                <div
                                  className="history-table-data"
                                  style={{ width: "15%" }}
                                >
                                  <div className="pending-badge">Due</div>
                                </div>
                                <div
                                  className="history-table-data"
                                  style={{ width: "20%" }}
                                >
                                  Created 2 month ago
                                </div>
                                <div
                                  className="history-table-data"
                                  style={{
                                    width: "5%",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <div
                                    className="table-data-actions"
                                    onClick={() => (
                                      setShowActionMenu(!showActionMenu),
                                      setActionMenu(2)
                                    )}
                                  >
                                    <img src={menuDots} alt="" />
                                  </div>
                                  {showActionMenu && actionMenu == 2 && (
                                    <div className="table-action-menu">
                                      <div className="action-menu-item">
                                        Edit
                                      </div>
                                      <div className="action-menu-item">
                                        Delete
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {selectedMenuItem == "change_password" && (
                        <div className="change-password-form">
                          <div className="right-form-upper">
                            <div className="right-form-body">
                              <div className="right-form-input-row">
                                <div className="input-box">
                                  <div className="input-label">
                                    Current Password
                                  </div>
                                  <div className="input-field">
                                    <input
                                      type={
                                        showCurrentPassword
                                          ? "password"
                                          : "text"
                                      }
                                      name="password"
                                      autoComplete="off"
                                      onChange={(e) => (
                                        setCurrentPassword(e.target.value),
                                        setShowAlertBox("")
                                      )}
                                    />
                                    <div
                                      className="password-eye"
                                      onClick={() =>
                                        setShowCurrentPassword(
                                          !showCurrentPassword
                                        )
                                      }
                                    >
                                      {showCurrentPassword ? (
                                        <img src={eye} alt="" />
                                      ) : (
                                        <img src={eyeCrossed} alt="" />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="right-form-input-row">
                                <div className="input-box">
                                  <div className="input-label">
                                    New Password
                                  </div>
                                  <div className="input-field">
                                    <input
                                      type={
                                        showNewPassword ? "password" : "text"
                                      }
                                      onChange={(e) => (
                                        setNewPassword(e.target.value),
                                        setShowAlertBox("")
                                      )}
                                    />
                                    <div
                                      className="password-eye"
                                      onClick={() =>
                                        setShowNewPassword(!showNewPassword)
                                      }
                                    >
                                      {showNewPassword ? (
                                        <img src={eye} alt="" />
                                      ) : (
                                        <img src={eyeCrossed} alt="" />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="right-form-input-row">
                                <div className="input-box">
                                  <div className="input-label">
                                    Confirm New Password
                                  </div>
                                  <div className="input-field">
                                    <input
                                      type={
                                        showConfirmNewPassword
                                          ? "password"
                                          : "text"
                                      }
                                      onChange={(e) => (
                                        setConfirmNewPassword(e.target.value),
                                        setShowAlertBox("")
                                      )}
                                    />
                                    <div
                                      className="password-eye"
                                      onClick={() =>
                                        setShowConfirmNewPassword(
                                          !showConfirmNewPassword
                                        )
                                      }
                                    >
                                      {showConfirmNewPassword ? (
                                        <img src={eye} alt="" />
                                      ) : (
                                        <img src={eyeCrossed} alt="" />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="right-form-lower">
                            <div
                              className="right-form-button"
                              onClick={() => changePassword()}
                            >
                              Save Details
                            </div>
                          </div>
                        </div>
                      )}
                      {selectedMenuItem == "profile_details" && (
                        <div className="profile-details-form">
                          <div className="right-form-upper">
                            <div className="right-form-body">
                              <div className="right-form-input-row">
                                <div className="input-box">
                                  <div className="input-label">First Name</div>
                                  <div className="input-field">
                                    <input
                                      type="text"
                                      value={firstName}
                                      onChange={(e) => (
                                        setFirstName(e.target.value),
                                        setShowAlertBox("")
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className="input-box">
                                  <div className="input-label">Last Name</div>
                                  <div className="input-field">
                                    <input
                                      type="text"
                                      value={lastName}
                                      onChange={(e) => (
                                        setLastName(e.target.value),
                                        setShowAlertBox("")
                                      )}
                                    />
                                    {/* {errors.lastName != null && (
                                      <div className="error-text">
                                        {errors.lastName}
                                      </div>
                                    )} */}
                                  </div>
                                </div>
                              </div>
                              <div className="right-form-input-row">
                                <div className="input-box">
                                  <div className="input-label">
                                    Email Address
                                  </div>
                                  <div className="input-field">
                                    <input type="text" value={email} />
                                    <div className="change-text">Change</div>
                                  </div>
                                </div>
                                <div className="input-box">
                                  <div className="input-label">
                                    Mobile Number
                                  </div>
                                  <div className="input-field">
                                    <input type="text" value={mobile} />
                                    <div className="change-text">Change</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="right-form-lower">
                            <div
                              className="right-form-button"
                              onClick={() => updateProfile()}
                            >
                              Save Details
                            </div>
                          </div>
                        </div>
                      )}
                      {selectedMenuItem == "payment_methods" && (
                        <div className="payment-method-section">
                          <div className="available-credits-section">
                            <div className="available-credit-left">
                              <div className="available-credit-amount">
                                $&nbsp;2102.08
                              </div>
                              <div className="available-credit-des">
                                These credits will be applied to your next
                                billing cycle automatically.
                              </div>
                            </div>
                            <div className="available-credit-right">
                              <div className="solid-btn">
                                Apply Credits Coupon
                              </div>
                            </div>
                          </div>
                          <div className="lower-right-heading">
                            Payment Methods
                          </div>
                          <div className="payment-method-cards">
                            <div className="payment-method">
                              <div className="payment-method-head">
                                <div className="payment-logo">
                                  <img src={master} alt="" />
                                </div>
                                <div className="payment-primary">Primary</div>
                              </div>
                              <div className="payment-method-body">
                                <div className="card-number">
                                  **** **** **** 1234
                                </div>
                                <div className="card-expiry">11/2024</div>
                              </div>
                              <div className="payment-method-foot">
                                <div className="card-type">Debit Card</div>
                                <div
                                  className="table-data-actions"
                                  onClick={() => (
                                    setShowActionMenu(!showActionMenu),
                                    setActionMenu(3)
                                  )}
                                >
                                  <img src={menuDots} alt="" />
                                </div>
                                {showActionMenu && actionMenu == 3 && (
                                  <div className="table-action-menu">
                                    <div className="action-menu-item">Edit</div>
                                    <div className="action-menu-item">
                                      Delete
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="payment-method">
                              <div className="payment-method-head">
                                <div className="payment-logo">
                                  <img src={visa} alt="" className="visa-img" />
                                </div>
                              </div>
                              <div className="payment-method-body">
                                <div className="card-number">
                                  **** **** **** 1234
                                </div>
                                <div className="card-expiry">11/2024</div>
                              </div>
                              <div className="payment-method-foot">
                                <div className="card-type">Debit Card</div>
                                <div
                                  className="table-data-actions"
                                  onClick={() => (
                                    setShowActionMenu(!showActionMenu),
                                    setActionMenu(4)
                                  )}
                                >
                                  <img src={menuDots} alt="" />
                                </div>
                                {showActionMenu && actionMenu == 4 && (
                                  <div className="table-action-menu">
                                    <div className="action-menu-item">Edit</div>
                                    <div className="action-menu-item">
                                      Delete
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="add-payment-method">
                              <div className="add-method-icon">
                                <img src={plus} alt="" />
                              </div>
                              <div className="add-method-text">
                                Add Payment Method
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
