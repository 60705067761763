import React, { useEffect, useState } from "react";
import { Homepage } from "./pages/Homepage/Homepage";
import { Login } from "./pages/Login/Login";
import { ForgotPassword } from "./pages/ForgotPassword/ForgotPassword";
import { OTP } from "./pages/OTP/OTP";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import { PrivacyPolicy } from "./pages/PrivacyPolicy/PrivacyPolicy";
import { GetInTouch } from "./pages/GetInTouch/GetInTouch";
import { TermsAndConditionns } from "./pages/TermsAndConditionns/TermsAndConditionns";
import api from "./AxiosInstance";
import { Page } from "./pages/Page/Page";
import { ErrorPage } from "./pages/ErrorPage/ErrorPage";
import { Register } from "./pages/Register/Register";
import { VerifyEmail } from "./pages/VerifyEmail/VerifyEmail";
import { VerifyMobile } from "./pages/VerifyMobile/VerifyMobile";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import { ResetPassword } from "./pages/ResetPassword/ResetPassword";

function App() {
  const [showSideNavbar, setShowSideNavbar] = useState(false);
  const [isPageRoute, setIsPageRoute] = useState(false);
  const location = useLocation();
  const { pathname } = location;

  const getPage = () => {
    const containsString = pathname.includes("/pages/");
    if (containsString) {
      setIsPageRoute(true);
    } else {
      setIsPageRoute(false);
    }
  };

  useEffect(() => {
    getPage();
  }, [pathname]);

  return (
    <div className="App">
      <Routes>
        <Route
          exact
          path="/"
          element={
            <Homepage
              showSideNavbar={showSideNavbar}
              setShowSideNavbar={setShowSideNavbar}
            />
          }
        />
        {isPageRoute && (
          <Route
            exact
            path={location.pathname}
            element={
              <Page
                showSideNavbar={showSideNavbar}
                setShowSideNavbar={setShowSideNavbar}
              />
            }
          />
        )}
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/signup" element={<Register />} />
        <Route exact path="/verify-email" element={<VerifyEmail />} />
        <Route exact path="/verify-mobile" element={<VerifyMobile />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/verify-otp" element={<OTP />} />
        <Route exact path="/reset-password" element={<ResetPassword />} />
        <Route
          exact
          path="/privacy-policy"
          element={
            <PrivacyPolicy
              showSideNavbar={showSideNavbar}
              setShowSideNavbar={setShowSideNavbar}
            />
          }
        />
        <Route
          exact
          path="/terms-conditions"
          element={
            <TermsAndConditionns
              showSideNavbar={showSideNavbar}
              setShowSideNavbar={setShowSideNavbar}
            />
          }
        />
        <Route exact path="/get-in-touch" element={<GetInTouch />} />
      </Routes>
    </div>
  );
}

export default App;
