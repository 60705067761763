import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../AxiosInstance";
import { Loader } from "../../components/Loader/Loader";
import { BackButton } from "../../components/BackButton/BackButton";
import "./style.css";

export const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    if (accessToken) {
      navigate("/dashboard");
    }
    if (!state) {
      navigate("/forgot-password");
    }
  }, []);

  const validations = () => {
    const errors = {};

    if (!password) {
      errors.password = "Password is required";
    }

    if (!confirmPassword) {
      errors.confirmPassword = "Confirm password is required";
    } else if (confirmPassword != password) {
      errors.confirmPassword = "New password confirmation does not match";
    }

    return errors;
  };

  useEffect(() => {
    document.title = "Reset Password";
  },[])

  const resetPassword = () => {
    const validationError = validations();
    if (Object.keys(validationError).length === 0) {
      setIsLoading(true);
      const payload = {
        email: state.email,
        reset_password_token: state.resetPasswordToken,
        password: password,
        confirm_password: confirmPassword,
      };
      api
        .post("/reset-password", payload)
        .then((response) => {
          setErrors({});
          navigate("/login");
        })
        .catch((error) => {
          setIsLoading(false);
        });
    } else {
      setErrors(validationError);
    }
  };

  const handlePasswordChange = (e) => {
    setErrors({ ...errors, password: null });
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setErrors({ ...errors, confirmPassword: null });
    setConfirmPassword(e.target.value);
  };
  return (
    <div className="reset">
      {isLoading && <Loader />}
      <div className="reset-inner">
        <div className="reset-head">
          <BackButton handleClick={() => navigate("/forgot-password")} />
        </div>
        <div className="reset-body">
          <div className="reset-card">
            <div className="card-head">Reset Password</div>
            <div className="card-label">Enter a new password</div>
            <div className="input-box">
              <div className="input-label">Password</div>
              <div className="input-label-mob password">Password</div>
              <div className="input-field">
                <input
                  type="password"
                  onChange={(e) => handlePasswordChange(e)}
                />
                {errors.password != null && (
                  <div className="error-text">{errors.password}</div>
                )}
              </div>
            </div>
            <div className="input-box">
              <div className="input-label">Confirm Password</div>
              <div className="input-label-mob confirm">Confirm Password</div>
              <div className="input-field">
                <input
                  type="password"
                  onChange={(e) => handleConfirmPasswordChange(e)}
                />
                {errors.confirmPassword != null && (
                  <div className="error-text">{errors.confirmPassword}</div>
                )}
              </div>
            </div>
            <div className="reset-submit-btn" onClick={() => resetPassword()}>
              Submit
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
