import React, { useState, useEffect, useRef } from "react";
import backArrow from "../../images/back-arrow.svg";
import { useNavigate } from "react-router-dom";
import { BackButton } from "../../components/BackButton/BackButton";
import { Loader } from "../../components/Loader/Loader";
import api from "../../AxiosInstance";
import "./style.css";

export const VerifyEmail = () => {
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(60);
  const [isDisabled, setIsDisabled] = useState(true);
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");
  const [input4, setInput4] = useState("");
  const [input5, setInput5] = useState("");
  const [input6, setInput6] = useState("");
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const input5Ref = useRef(null);
  const input6Ref = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    if (!accessToken) {
      navigate("/");
    } else {
      navigate("/dashboard");
    }
    if (seconds > 0) {
      const timer = setTimeout(() => setSeconds((seconds - 1).toString().padStart(2, "0")), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsDisabled(false);
    }
  }, [seconds]);

  const handleSendOtp = () => {
    if (!isDisabled) {
      sendEmailVerificationCode();
      setSeconds(60);
      setIsDisabled(true);
    }
  };

  const sendEmailVerificationCode = () => {
    setIsLoading(true);
    api
      .post(
        "/email-verification-code",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        if (response.data.success) {
          setError("");
        } else {
          setError(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const verifyEmail = () => {
    if (!input1 || !input2 || !input3 || !input4 || !input5 || !input6) {
      setError("Code is required!");
      return ;
    }
    setIsLoading(true);
    const payload = {
      code: input1 + input2 + input3 + input4 + input5 + input6,
    };
    api
      .post("/verify-email", payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })
      .then((response) => {
        if (response.data.success) {
          setError("");
          sendMobileVerificationCode();
        } else {
          setError(response.data.message);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const sendMobileVerificationCode = () => {
    api
      .post(
        "/mobile-verification-code",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        if (response.data.success) {
          navigate("/verify-mobile");
        } else {
          setError(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleInput1Change = (e, nextInputRef) => {
    const value = e.target.value;
    setInput1(value);
    if (value.length === 1 && nextInputRef) {
      nextInputRef.current.focus();
    }
  };
  const handleInput2Change = (e, nextInputRef) => {
    const value = e.target.value;
    setInput2(value);
    if (value.length === 1 && nextInputRef) {
      nextInputRef.current.focus();
    }
  };
  const handleInput3Change = (e, nextInputRef) => {
    const value = e.target.value;
    setInput3(value);
    if (value.length === 1 && nextInputRef) {
      nextInputRef.current.focus();
    }
  };
  const handleInput4Change = (e, nextInputRef) => {
    const value = e.target.value;
    setInput4(value);
    if (value.length === 1 && nextInputRef) {
      nextInputRef.current.focus();
    }
  };
  const handleInput5Change = (e, nextInputRef) => {
    const value = e.target.value;
    setInput5(value);
    if (value.length === 1 && nextInputRef) {
      nextInputRef.current.focus();
    }
  };

  useEffect(() => {
    document.title = "Verify Email";
  },[])

  return (
    <div className="verify-email">
      {isLoading && <Loader />}
      <div className="verify-email-inner">
        <div className="verify-email-head">
          <BackButton handleClick={() => navigate("/signup")} />
        </div>
        <div className="verify-email-body">
          <div className="verify-email-card">
            <div className="card-head">Email Verification</div>
            <div className="card-label">
              Please enter 6 digits code sent to your email address.
            </div>
            <div className="input-box">
              <div className="input-field">
                <input
                  type="text"
                  ref={input1Ref}
                  onChange={(e) => handleInput1Change(e, input2Ref)}
                  maxLength="1"
                  className="otp-field"
                />
              </div>
              <div className="input-field">
                <input
                  type="text"
                  ref={input2Ref}
                  onChange={(e) => handleInput2Change(e, input3Ref)}
                  maxLength="1"
                  className="otp-field"
                />
              </div>
              <div className="input-field">
                <input
                  type="text"
                  ref={input3Ref}
                  onChange={(e) => handleInput3Change(e, input4Ref)}
                  maxLength="1"
                  className="otp-field"
                />
              </div>
              <div className="input-field">
                <input
                  type="text"
                  ref={input4Ref}
                  onChange={(e) => handleInput4Change(e, input5Ref)}
                  maxLength="1"
                  className="otp-field"
                />
              </div>
              <div className="input-field">
                <input
                  type="text"
                  ref={input5Ref}
                  onChange={(e) => handleInput5Change(e, input6Ref)}
                  maxLength="1"
                  className="otp-field"
                />
              </div>
              <div className="input-field">
                <input
                  type="text"
                  ref={input6Ref}
                  onChange={(e) => setInput6(e.target.value)}
                  maxLength="1"
                  className="otp-field"
                />
              </div>
            </div>
            {error != "" && <div className="error-text">{error}</div>}
            <div className="dont-get">
              Didn’t get OTP?{" "}
              <span
                className="text-link"
                onClick={handleSendOtp}
                disabled={isDisabled}
              >
                {isDisabled ? "00:" + seconds : "Send OTP"}
              </span>
            </div>
            <div
              className="verify-email-submit-btn"
              onClick={() => verifyEmail()}
            >
              Submit
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
