import React from "react";
import backArrow from "../../images/back-arrow.svg";
import "./style.css";

export const BackButton = (props) => {
  const { handleClick } = props;
  return (
    <div className="back-button" onClick={() => handleClick()}>
      <div className="back-img">
        <img src={backArrow} alt="" />
      </div>
      <div className="back-btn-text">Back</div>
    </div>
  );
};
